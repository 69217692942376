<template>
  <div class="relative">
    <FilterButton
      :class="cssClassesForFilterBtn"
      :width="filterBtnWidth"
      @click="showFilters = !showFilters"
    >
    </FilterButton>
    <div
      v-if="showFilters"
      class="fixed top-0 left-0 z-10 h-full w-full"
      @click="showFilters = false"
    ></div>

    <div v-if="showFilters" class="absolute right-0 top-10 z-20">
      <FilterCard>
        <template #main>
          <slot :close-filters="closeFilters"> </slot>
        </template>
      </FilterCard>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { defineProps, ref, watch } from 'vue'
import FilterButton from './FilterButton.vue'
import FilterCard from './FilterCard.vue'
import { useLookalikesStore } from '@/stores/lookalikes'

interface IProps {
  cssClassesForFilterBtn?: string
  filterBtnWidth?: string
}

defineProps<IProps>()

const store = useLookalikesStore()
const showFilters = ref(false)

const closeFilters = () => {
  showFilters.value = false
}

watch(
  () => store.lookalikeCurrentHistorySearchId,
  () => {
    showFilters.value = false
  }
)
</script>
