<template>
  <div>
    <h1
      v-if="pageTitle"
      class="font-serif mt-0 mb-10 text-3xl font-semibold leading-9 text-slate-800"
    >
      {{ title }}
    </h1>

    <h2 v-if="showFilterInTitle && filterTitle" class="mb-8 text-xl font-semibold text-slate-800">
      {{ filterTitle }}
    </h2>

    <div class="flex flex-col">
      <TabsBar :tabs="tabs" :current-tab="currentTab" @tab-selected="tabSelected" />

      <component :is="currentTabComponent" :data="data" @filtered="changeFilterTitle"></component>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, defineEmits, defineProps, onMounted, ref } from 'vue'
import type { Component } from 'vue'
import TabsBar from '../TabsBar.vue'
import type { Tabs } from './interfaces'

interface IProps {
  tabs: Tabs
  pageTitle?: string
  showFilterInTitle?: boolean
  formatFilterValues?(filter: Record<string, number | string>): Record<string, number | string>
  data?: any
}

const emit = defineEmits(['tabSelected'])

const props = defineProps<IProps>()

const tabNameLocal = ref('')
const currentTabComponent = ref<Component | null>(null)
const currentTab = ref('')
const filterTitle = ref('')

const title = computed(
  () => `${tabNameLocal.value} ${props.pageTitle ? '-' : ''}  ${props.pageTitle ?? ''}`
)

const resetFilterTitle = (): void => {
  filterTitle.value = ''
}

const tabSelected = (tabName: string, componentName: Component, tabRouteName: string): void => {
  currentTabComponent.value = componentName

  tabNameLocal.value = tabName
  resetFilterTitle()

  history.pushState(history.state, '', `${location.origin}${location.pathname}?tab=${tabRouteName}`)

  emit('tabSelected', tabName, componentName)
}

const changeFilterTitle = (filter: Record<string, number | string>): void => {
  const copyFilter = Object.assign({}, filter)

  if (props.formatFilterValues) {
    props.formatFilterValues(copyFilter)
  }

  const filtersValues = Object.values(copyFilter)
    .filter((val) => !!val)
    .join(' | ')

  filterTitle.value = filtersValues ? `Filtered: ${filtersValues}` : ''
}

onMounted(() => {
  const searchParams = new URLSearchParams(location.search)

  const rawTab = searchParams.get('tab') ?? ''
  const tab = Object.keys(props.tabs).includes(rawTab)
    ? props.tabs[rawTab]
    : props.tabs[Object.keys(props.tabs)[0]]

  currentTab.value = tab.displayName
  currentTabComponent.value = tab.component

  tabNameLocal.value = tab.displayName

  emit('tabSelected', tab.displayName, tab.component)
})
</script>
