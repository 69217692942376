<template>
  <div>
    <ul class="flex">
      <li
        v-for="(displayNameAndComponent, tabRouteName) in tabs"
        :key="tabRouteName"
        :class="[
          {
            'rounded-t-lg bg-white shadow': displayNameAndComponent.displayName === currentTabLocal
          },
          { 'hover:bg-slate-50': displayNameAndComponent.displayName !== currentTabLocal }
        ]"
        class="cursor-pointer px-5 py-2 text-center text-lg font-semibold text-primary-500 hover:rounded-t-lg"
        @click="
          tabSelected(
            displayNameAndComponent.displayName,
            displayNameAndComponent.component,
            tabRouteName
          )
        "
      >
        {{ displayNameAndComponent.displayName }}
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import { defineEmits, defineProps, ref, watch } from 'vue'
import type { Component } from 'vue'

interface IProps {
  tabs: Record<string, { displayName: string; component: Component }>
  currentTab: string
}

const emit = defineEmits(['tab-selected'])

const props = defineProps<IProps>()

const currentTabLocal = ref(null)

watch(
  () => props.currentTab,
  (tabName) => {
    currentTabLocal.value = tabName
  }
)

const tabSelected = (
  tabDisplayName: string,
  tabComponent: Component,
  tabRouteName: string
): void => {
  if (tabDisplayName === currentTabLocal.value) {
    return
  }

  currentTabLocal.value = tabDisplayName

  emit('tab-selected', tabDisplayName, tabComponent, tabRouteName)
}
</script>
