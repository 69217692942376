<template>
  <Head title="Lookalikes" />

  <LookAlikesResults
    v-if="lookalikesData && lookalikesData.searchType === 'lookalike'"
    @shortlist-failed="shortlistFailed"
    @shortlist-succeed="shortlistSucceed"
    @search-edited="handleSearchEdited"
    @stop-fetching-results="stopFetchingResults"
  />

  <BuildArchetypeResults
    v-if="lookalikesData && lookalikesData.searchType === 'build_archetype'"
    @shortlist-failed="shortlistFailed"
    @shortlist-succeed="shortlistSucceed"
    @stop-fetching-results="stopFetchingResults"
    @search-finished="handleSearchEdited"
    @error="error = true"
  />

  <Toaster
    v-if="isLocationFallback"
    message="We found less than 2000 Lookalikes for your selected location. To provide richer results, Lookalikes from a bigger search radius are displayed as well."
    color="green"
    :is-constant="true"
    @hide-toaster="closeLocationFallbackMessage"
  />
  <Toaster
    v-if="error"
    message="Oops something went wrong. please contact our support team"
    color="red"
    :time="5000"
    @hide-toaster="error = false"
  />
  <Toaster
    v-if="shortlistSuccess"
    message="Candidate Shortlisted"
    color="green"
    :time="3000"
    @hide-toaster="shortlistSuccess = false"
  />
  <Toaster
    v-if="shortlistError"
    :message="shortlistErrorMessage"
    color="red"
    :time="3000"
    @hide-toaster="shortlistError = false"
  />
</template>

<script setup lang="ts">
import { computed, onBeforeMount, onBeforeUnmount, ref, watch } from 'vue'
import { useRoute } from 'vue-router'

import Toaster from '@/components/Toaster.vue'
import { update } from '@intercom/messenger-js-sdk'
import { useLookalikesStore } from '@/stores/lookalikes'
import BuildArchetypeResults from './BuildArchetypeResults/BuildArchetypeResults.vue'
import LookAlikesResults from './LookalikesResults/LookAlikesResults.vue'

const store = useLookalikesStore()
const route = useRoute()

const error = ref(false)
const loading = ref(false)
const shortlistErrorMessage = ref('')
const shortlistError = ref(false)
const shortlistSuccess = ref(false)

let intervalId = null

const lookalikesData = computed(() => store.searchResults)

const isLocationFallback = computed(() =>
  store.searchResults && store.searchResults.isLocationFallback
    ? store.searchResults.isLocationFallback
    : null
)

const closeLocationFallbackMessage = () => {
  isLocationFallback.value = false
  update({ hide_default_launcher: false })
}

onBeforeMount(async () => {
  if (!lookalikesData.value) {
    loading.value = true
    try {
      await store.fetchSearchResults(parseInt(route.params.searchId))
      store.setLookalikeCurrentHistorySearchId(parseInt(route.params.searchId))
    } catch {
      error.value = true
    }
    loading.value = false
  }
})

// fetch shortlisted candidates every 30 seconds to real-time update table
intervalId = setInterval(() => {
  store.fetchSearchResults(parseInt(route.params.searchId))
}, 60000)

onBeforeUnmount(() => {
  clearInterval(intervalId)
})

watch(
  () => isLocationFallback.value,
  () => {
    if (isLocationFallback.value) {
      update({ hide_default_launcher: true })
    }
  },
  { immediate: true }
)

const resumeFetchResults = () => {
  intervalId = setInterval(() => {
    store.fetchSearchResults(parseInt(route.params.searchId))
  }, 60000)
}

const handleSearchEdited = () => {
  resumeFetchResults()
}

const stopFetchingResults = () => {
  clearInterval(intervalId)
}

const shortlistSucceed = (): void => {
  shortlistSuccess.value = true
}

const shortlistFailed = (errorMsg: string) => {
  shortlistError.value = true
  shortlistErrorMessage.value = errorMsg
}
</script>
