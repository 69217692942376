<template>
  <TableFilter css-classes-for-filter-btn="justify-between" filter-btn-width="w-[185px]">
    <template #default>
      <AutoCompleteInput
        :ref="setFiltersRef"
        class="border-b border-b-gray-200 p-2"
        title="Country"
        placeholder-text="Example: United States"
        :multi-select="true"
        :static-options="
          Array.from(countryCol.getFacetedUniqueValues().keys())
            .filter((val) => val !== '' && val !== null)
            .sort()
            .slice(0, 5000)
        "
        :show-static-options="false"
        :show-static-options-on-focus="true"
        :auto-complete-value="countryCol.getFilterValue()"
        :multi-selected-values="convertArrToObj(localSelectedCountries)"
        @option-selected="setCountryFilter"
      />
      <AutoCompleteInput
        :ref="setFiltersRef"
        class="border-b border-b-gray-200 p-2"
        title="State"
        placeholder-text="Example: Alabama"
        :multi-select="true"
        :static-options="
          Array.from(stateCol.getFacetedUniqueValues().keys())
            .filter((val) => val !== '' && val !== null)
            .sort()
            .slice(0, 5000)
        "
        :show-static-options="false"
        :show-static-options-on-focus="true"
        :auto-complete-value="stateCol.getFilterValue()"
        :multi-selected-values="convertArrToObj(localSelectedStates)"
        @option-selected="setStateFilter"
      />
      <AutoCompleteInput
        :ref="setFiltersRef"
        class="border-b border-b-gray-200 p-2"
        title="City"
        placeholder-text="Example: New York"
        :multi-select="true"
        :static-options="
          Array.from(cityCol.getFacetedUniqueValues().keys())
            .filter((val) => val !== '' && val !== null)
            .sort()
            .slice(0, 5000)
        "
        :show-static-options="false"
        :show-static-options-on-focus="true"
        :auto-complete-value="cityCol.getFilterValue()"
        :multi-selected-values="convertArrToObj(localSelectedCities)"
        @option-selected="setCityFilter"
      />
    </template>
  </TableFilter>
</template>

<script setup lang="ts">
import { computed, defineEmits, defineProps, ref } from 'vue'

import TableFilter from '@/components/table/tableFilter/TableFilter.vue'
import AutoCompleteInput from '@/components/inputs/AutoCompleteInput.vue'
import { convertArrToObj } from '@/utils/type-format-helper'

interface IProps {
  table: any
}

const props = defineProps<IProps>()
const emit = defineEmits(['filterTable'])

const localSelectedCountries = ref([])
const localSelectedStates = ref([])
const localSelectedCities = ref([])
const filters = ref([])

const countryCol = computed(() => (props.table ? props.table.getColumn('country') : []))
const stateCol = computed(() => (props.table ? props.table.getColumn('state') : []))
const cityCol = computed(() => (props.table ? props.table.getColumn('city') : []))

console.log(countryCol.value.getFacetedUniqueValues())

const setCountryFilter = (val) => {
  const countryVal = val === '' ? null : val
  localSelectedCountries.value = val === '' ? [] : val

  countryCol.value.setFilterValue(countryVal)
  emit('filterTable')
}

const setStateFilter = (val) => {
  const stateVal = val === '' ? null : val
  localSelectedStates.value = val === '' ? [] : val

  stateCol.value.setFilterValue(stateVal)
  emit('filterTable')
}

const setCityFilter = (val) => {
  const cityVal = val === '' ? null : val
  localSelectedCities.value = val === '' ? [] : val

  cityCol.value.setFilterValue(cityVal)
  emit('filterTable')
}

const setFiltersRef = (element) => {
  if (element) {
    filters.value.push(element)
  }
}
</script>
