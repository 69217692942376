<template>
  <CompareToAverageCardWithHeader
    title="Average Annual Salary"
    description="HR Signal leverages a proprietary combination of many market data sources to generate highly accurate compensation metrics. Our market compensation benchmarks leverage current salary data and forward-looking salary data from the hiring market."
    :data="avgSalary"
    not-available-data-message="Salary Not Available"
    :percentage-value="percentageValue"
    :percentage-value-title="percentageValueTitle"
    :up-down-same="upDownSame"
    :data-title="salaryCurrencyTitle"
  ></CompareToAverageCardWithHeader>
</template>

<script lang="ts" setup>
import { computed, defineProps } from 'vue'

import CompareToAverageCardWithHeader from '@/components/Cards/MetricCards/CompareToAverageCardWithHeader/CompareToAverageCardWithHeader.vue'
import type { UpDownSame } from '@/components/interfaces'

interface IProps {
  percentageValue?: number | null
  upDownSame?: UpDownSame | null
  avgAnnualSalary: string | null
  salaryCurrencyTitle: string
  monthsAgo?: string
}

const props = defineProps<IProps>()

const avgSalary = computed(() =>
  props.avgAnnualSalary ? `$${parseInt(props.avgAnnualSalary, 10).toLocaleString('en-US')}` : null
)

const monthsAgo = props.monthsAgo ?? '12'

const percentageValueTitle = computed(() => {
  switch (props.upDownSame) {
    case 'up':
      return `higher compared to ${monthsAgo} months ago`
    case 'down':
      return `lower compared to ${monthsAgo} months ago`
    case 'same':
      return `same as compared to ${monthsAgo} months ago`
    default:
      return 'Data for this position/location did not load.'
  }
})
</script>
