<template>
  <a
    :href="link"
    class="focus:outline-acne inline-flex h-9 items-center rounded px-3 text-sm font-bold text-white shadow ring-primary-200 last:mr-0 focus:ring dark:text-gray-800 dark:ring-gray-600"
    :class="[hoverColors[bgColor] ?? hoverColors.default, bgColors[bgColor] ?? bgColors.default]"
    :target="target ?? '_self'"
    :rel="rel"
    @click.stop=""
  >
    {{ text }}
  </a>
</template>

<script setup lang="ts">
import { defineProps } from 'vue'

interface IProps {
  link: string
  text: string
  bgColor?: string
  download?: boolean
  target: '_self' | '_blank'
  rel?: string
}

defineProps<IProps>()

const bgColors = {
  default: 'bg-primary-500',
  green: 'bg-green-500',
  red: 'bg-red-500'
}

const hoverColors = {
  default: 'hover:bg-primary-400',
  green: 'hover:bg-green-400',
  red: 'hover:bg-red-400'
}
</script>

<style scoped>
@media (prefers-color-scheme: dark) {
  a {
    color: white !important;
  }
}
</style>
