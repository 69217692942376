<template>
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
       width="20" height="20" viewBox="0 0 393.000000 478.000000"
       preserveAspectRatio="xMidYMid meet">

    <g transform="translate(0.000000,478.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
      <path
        d="M2766 4549 c-10 -111 -44 -312 -67 -403 -86 -335 -273 -455 -803 -516 -167 -19 -165 -21 40 -45 396 -45 607 -142 697 -322 56 -111 100 -306
      130 -578 16 -137 15 -139 47 125 37 299 85 444 182 546 123 130 331 204 658 235 69 7 131 15 138 19 7 3 -43 12 -110 19 -361 38 -551 103 -675 229 -38 38
       -74 86 -92 125 -49 105 -96 319 -117 542 -10 100 -20 109 -28 24z"
      />
      <path
        d="M926 3840 c-35 -350 -95 -516 -221 -612 -92 -69 -290 -124 -523 -144 -45 -3 -81 -8 -80 -10 2 -1 53 -8 113 -14 127 -13 295 -49 377 -80 85 -31 172
      -100 208 -162 60 -105 104 -283 126 -518 l7 -75 23 175 c34 261 84 402 170 484 91 87 244 139 504 172 74 9 136 17 138 19 1 1 -35 5 -80 9 -279 23 -484
      90 -575 189 -85 92 -123 205 -157 471 l-22 171 -8 -75z"
      />
      <path
        d="M2080 3208 c0 -49 -43 -398 -65 -523 -56 -329 -123 -502 -245 -637 -182 -201 -466 -295 -1104 -363 -32 -4 -54 -10 -50 -15 5 -4 68 -13 139 -19
      195 -18 532 -80 646 -120 224 -78 380 -200 466 -367 46 -88 95 -223 117 -317 22 -96 63 -375 82 -552 9 -88 17 -161 18 -163 2 -1 11 73 20 165 22 208 60
      461 87 573 24 103 87 263 134 339 96 159 298 288 548 351 131 33 381 74 552 90 164 16 190 25 100 34 -327 33 -627 86 -758 133 -381 136 -535 361 -621 903
      -8 52 -25 185 -37 295 -11 110 -23 202 -25 204 -2 2 -4 -3 -4 -11z"
      />
    </g>
  </svg>
</template>
