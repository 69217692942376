<template>
  <ChartCardWithHeader
    title="Average Annual Salary History"
    :show-data-explanation="showDataExplanation"
    explanation="HR Signal’s salary history data varies by position. Most positions have at least 6 months of historical data, with some positions having more or less salary history."
  >
    <template #graph>
      <AreaChart
        :series="isThereDataForChart ? salarySeries : mockSeries"
        :categories="isThereDataForChart ? salaryHistoryDates : mockCategories"
        :data-value-formatter="formatter"
        :mock-data="!isThereDataForChart"
        no-data-message="Data is currently not available for this candidate in this specific location."
      />
    </template>
  </ChartCardWithHeader>
</template>

<script lang="ts" setup>
import ChartCardWithHeader from '@/components/Cards/ChartCards/ChartCardWithHeader.vue'
import AreaChart from '@/components/Charts/AreaChart.vue'
import { getLastXMonths } from '../../../../../utils'

interface IProps {
  currency?: string
  rawSeries: { salary: number; timestamp: string }[]
  rawCategories: { salary: number; timestamp: string }[]
  showDataExplanation?: boolean
}

const props = withDefaults(defineProps<IProps>(), {
  currency: '$'
})

const isThereDataForChart = props.rawSeries?.length > 0
let salarySeries: { name: string; data: string | number[] }[]
let salaryHistoryDates: string[]

if (isThereDataForChart) {
  salaryHistoryDates = props.rawCategories.map((data) => {
    const date = new Date(data.timestamp)

    return `${date.toLocaleString('default', { month: 'short' })} ${date.getFullYear().toString().substring(2)}`
  })

  salarySeries = [
    {
      name: 'Salary',
      data: props.rawSeries.map((data) => data.salary)
    }
  ]
}

const mockSeries = [
  {
    name: 'Salary',
    data: [41_290, 42_859, 44_487, 46_178, 47_933]
  }
]

const mockCategories = getLastXMonths(5)

const formatter = (val: number): string => `${props.currency}${val.toLocaleString('en-US')}`
</script>
