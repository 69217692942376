import dayjs from 'dayjs';

export const getLastXMonths = (months: number): string[] => {
    const now = dayjs();
    const monthsArr = [];

    for (let i = months; i > 0; i--) {
        monthsArr.push(now.subtract(i, 'month').format('MMM YY'));
    }

    return monthsArr;
};
export function formatInputDate(dateString: string): string {
    if (typeof dateString === 'undefined') {
        return dateString;
    }

    const date = new Date(dateString);

    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date
        .getDate()
        .toString()
        .padStart(2, '0')}`;
}

export const convertMonthsToYearsMonths = (months: number) => {
    // Calculate the number of years
    const years = Math.floor(months / 12);
    // Calculate the remaining months
    const remainingMonths = months % 12;

    return years < 1
        ? `${remainingMonths} months`
        : remainingMonths > 0
        ? `${years}+ years`
        : years === 1
        ? `${years} year`
        : `${years} years`;
};
