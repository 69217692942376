<template>
  <TableFilter css-classes-for-filter-btn=" justify-between" filter-btn-width="w-[185px]">
    <template #default="{ closeFilters }">
      <AutoCompleteInput
        :ref="setFiltersRef"
        class="border-b border-b-gray-200 p-2"
        title="Country"
        placeholder-text="Example: United States"
        :multi-select="true"
        :static-options="
          Array.from(countryCol.getFacetedUniqueValues().keys())
            .filter((val) => val !== '' && val !== null)
            .sort()
            .slice(0, 5000)
        "
        :show-static-options="false"
        :show-static-options-on-focus="true"
        :auto-complete-value="countryCol.getFilterValue()"
        :multi-selected-values="convertArrToObj(localSelectedCountries)"
        @option-selected="setCountryFilter"
      />
      <AutoCompleteInput
        :ref="setFiltersRef"
        class="border-b border-b-gray-200 p-2"
        title="State"
        placeholder-text="Example: Alabama"
        :multi-select="true"
        :static-options="
          Array.from(stateCol.getFacetedUniqueValues().keys())
            .filter((val) => val !== '' && val !== null)
            .sort()
            .slice(0, 5000)
        "
        :show-static-options="false"
        :show-static-options-on-focus="true"
        :auto-complete-value="stateCol.getFilterValue()"
        :multi-selected-values="convertArrToObj(localSelectedStates)"
        @option-selected="setStateFilter"
      />
      <AutoCompleteInput
        :ref="setFiltersRef"
        class="border-b border-b-gray-200 p-2"
        title="City"
        placeholder-text="Example: New York"
        :multi-select="true"
        :static-options="
          Array.from(cityCol.getFacetedUniqueValues().keys())
            .filter((val) => val !== '' && val !== null)
            .sort()
            .slice(0, 5000)
        "
        :show-static-options="false"
        :show-static-options-on-focus="true"
        :auto-complete-value="cityCol.getFilterValue()"
        :multi-selected-values="convertArrToObj(localSelectedCities)"
        @option-selected="setCityFilter"
      />
      <AutoCompleteInput
        :ref="setFiltersRef"
        class="border-b border-b-gray-200 p-2"
        title="Industry"
        placeholder-text="Example: Telecommunications"
        :multi-select="true"
        :static-options="
          Array.from(industryCol.getFacetedUniqueValues().keys()).sort().slice(0, 5000)
        "
        :show-static-options="false"
        :show-static-options-on-focus="true"
        :auto-complete-value="industryCol.getFilterValue()"
        :multi-selected-values="convertArrToObj(localSelectedIndustries)"
        @option-selected="setIndustryFilter"
      />
      <div class="flex border-b border-b-gray-200 p-2">
        <MinMaxInput
          :ref="setFiltersRef"
          title="Total Role Experience"
          :min-value="roleExpMinValue"
          :max-value="roleExpMaxValue"
          min-input-min-valid="0"
          min-input-max-valid="100"
          max-input-max-valid="100"
          min-placeholder="min years"
          max-placeholder="max years"
          @min-value-change="setRoleExpMinFilter"
          @max-value-change="setRoleExpMaxFilter"
        />
      </div>
      <div class="flex border-b border-b-gray-200 p-2">
        <MinMaxInput
          :ref="setFiltersRef"
          title="Career Experience"
          :min-value="careerExpMinValue"
          :max-value="careerExpMaxValue"
          min-input-min-valid="0"
          min-input-max-valid="100"
          max-input-max-valid="100"
          min-placeholder="min years"
          max-placeholder="max years"
          @min-value-change="setCareerExpMinFilter"
          @max-value-change="setCareerExpMaxFilter"
        />
      </div>
      <div class="flex border-b border-b-gray-200 p-2 justify-center">
        <BaseButton
          class="w-[50%]"
          text="Clear"
          :text-align-center="true"
          @on-click="resetFilters"
        />
        <BaseButton
          class="w-[50%]"
          text="Close"
          :text-align-center="true"
          @on-click="closeFilters"
        />
      </div>
    </template>
  </TableFilter>
</template>

<script setup lang="ts">
import { computed, defineEmits, defineProps, ref, watch } from 'vue'

import TableFilter from '@/components/table/tableFilter/TableFilter.vue'
import AutoCompleteInput from '@/components/inputs/AutoCompleteInput.vue'

import MinMaxInput from '@/components/inputs/MinMaxInput.vue'
import BaseButton from '@/components/BaseButton.vue'
import { convertArrToObj } from '@/utils/type-format-helper'
import { useLookalikesStore } from '@/stores/lookalikes'

interface IProps {
  table: any
}

const props = defineProps<IProps>()
const emit = defineEmits(['filterTable'])

const store = useLookalikesStore()
const localSelectedCountries = ref([])
const localSelectedStates = ref([])
const localSelectedCities = ref([])
const localSelectedIndustries = ref([])

const minYears = ref<null | string | number>(null)
const maxYears = ref<null | string | number>(null)

const roleExpCol = computed(() => (props.table ? props.table.getColumn('roleExp') : []))
const careerExpCol = computed(() => (props.table ? props.table.getColumn('careerExp') : []))
const industryCol = computed(() => (props.table ? props.table.getColumn('industry') : []))
const countryCol = computed(() => (props.table ? props.table.getColumn('country') : []))
const stateCol = computed(() => (props.table ? props.table.getColumn('state') : []))
const cityCol = computed(() => (props.table ? props.table.getColumn('city') : []))

const roleExpMinValue = computed(() =>
  roleExpCol.value.getFilterValue() ? roleExpCol.value.getFilterValue()[0] / 12 : null
)
const roleExpMaxValue = computed(() =>
  roleExpCol.value.getFilterValue() ? roleExpCol.value.getFilterValue()[1] / 12 : null
)
const careerExpMinValue = computed(() =>
  careerExpCol.value.getFilterValue() ? careerExpCol.value.getFilterValue()[0] / 12 : null
)
const careerExpMaxValue = computed(() =>
  careerExpCol.value.getFilterValue() ? careerExpCol.value.getFilterValue()[1] / 12 : null
)
const setRoleExpMinFilter = (minYearsVal: string) => {
  if (minYearsVal === '') {
    minYears.value = ''
  } else if (Number(minYearsVal) < 0) {
    return
  } else {
    minYears.value = Number(minYearsVal) * 12 // convert to months
  }
  roleExpCol.value.setFilterValue(() => [minYears.value, maxYears.value])
  emit('filterTable')
}

const setRoleExpMaxFilter = (maxYearsVal: string) => {
  if (maxYearsVal === '') {
    maxYears.value = ''
  } else if (Number(maxYearsVal) < 0) {
    return
  } else {
    maxYears.value = Number(maxYearsVal) * 12 //convert to months
  }
  roleExpCol.value.setFilterValue(() => [minYears.value, maxYears.value])
  emit('filterTable')
}

const setCareerExpMinFilter = (minYearsVal: string) => {
  if (minYearsVal === '') {
    minYears.value = ''
  } else if (Number(minYearsVal) < 0) {
    return
  } else {
    minYears.value = Number(minYearsVal) * 12 // convert to months
  }
  careerExpCol.value.setFilterValue(() => [minYears.value, maxYears.value])
  emit('filterTable')
}

const setCareerExpMaxFilter = (maxYearsVal: string) => {
  if (maxYearsVal === '') {
    maxYears.value = ''
  } else if (Number(maxYearsVal) < 0) {
    return
  } else {
    maxYears.value = Number(maxYearsVal) * 12 //convert to months
  }
  careerExpCol.value.setFilterValue(() => [minYears.value, maxYears.value])
  emit('filterTable')
}

const setIndustryFilter = (val) => {
  const industryVal = val === '' ? null : val
  localSelectedIndustries.value = val === '' ? [] : val

  industryCol.value.setFilterValue(industryVal)
  emit('filterTable')
}

const setCountryFilter = (val) => {
  const countryVal = val === '' ? null : val
  localSelectedCountries.value = val === '' ? [] : val

  countryCol.value.setFilterValue(countryVal)
  emit('filterTable')
}

const setStateFilter = (val) => {
  const stateVal = val === '' ? null : val
  localSelectedStates.value = val === '' ? [] : val

  stateCol.value.setFilterValue(stateVal)
  emit('filterTable')
}

const setCityFilter = (val) => {
  const cityVal = val === '' ? null : val
  localSelectedCities.value = val === '' ? [] : val

  cityCol.value.setFilterValue(cityVal)
  emit('filterTable')
}

const filters = ref([])

const setFiltersRef = (element) => {
  if (element) {
    filters.value.push(element)
  }
}

const resetFilters = () => {
  filters.value.map((filter) => filter.clear())
}

watch(
  () => store.lookalikeCurrentHistorySearchId,
  () => {
    resetFilters()
  }
)
</script>
