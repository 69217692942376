<template>
  <svg
    width="18px"
    height="18px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.5"
      d="M2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.3011 6.91463C9.58205 7.219 9.56307 7.69349 9.25871 7.97445L8.41824 8.75027H14.0385C16.3644 8.75027 18.25 10.6358 18.25 12.9618C18.25 15.2878 16.3644 17.1733 14.0385 17.1733H9.5C9.08579 17.1733 8.75 16.8376 8.75 16.4233C8.75 16.0091 9.08579 15.6733 9.5 15.6733H14.0385C15.536 15.6733 16.75 14.4593 16.75 12.9618C16.75 11.4643 15.536 10.2503 14.0385 10.2503H8.41824L9.25871 11.0261C9.56307 11.307 9.58205 11.7815 9.3011 12.0859C9.02015 12.3903 8.54565 12.4092 8.24129 12.1283L5.99129 10.0514C5.83748 9.90939 5.75 9.70959 5.75 9.50027C5.75 9.29094 5.83748 9.09114 5.99129 8.94916L8.24129 6.87224C8.54565 6.59129 9.02015 6.61027 9.3011 6.91463Z"
      fill="#1C274C"
    />
  </svg>
</template>
