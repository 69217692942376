<template>
  <BaseCard class="max-w-[448px] card-shadow m-auto">
    <template #main>
      <div class="m-auto p-8 w-full">
        <p v-if="errorMessage" class="text-rose-600 mb-2">{{ errorMessage }}</p>
        <form @submit.prevent="resetPassword">
          <Input
            type="email"
            label="Email"
            label-size="small"
            @input-change="(value) => (email = value)"
            required
          />
          <Input
            class="mt-8"
            title="password"
            type="password"
            label-size="small"
            required
            @input-change="(value) => (password = value)"
          />
          <Input
            class="mt-8"
            title="confirm password"
            type="password"
            label-size="small"
            required
            @input-change="(value) => (confirmPassword = value)"
          />
          <BaseButton
            class="ml-auto mt-8"
            text="RESET PASSWORD "
            type="submit"
            :loading="loading"
          />
        </form>
      </div>
    </template>
  </BaseCard>
  <Toaster
    v-if="passwordResetSuccessfully"
    color="green"
    :time="3000"
    message="password has been successfully reset. redirecting to login page"
    @hide-toaster="redirectToLogin"
  />
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import BaseCard from '@/components/BaseCard.vue'
import Input from '@/components/inputs/Input.vue'
import BaseButton from '@/components/BaseButton.vue'
import Toaster from '@/components/Toaster.vue'

import { captureException } from '@sentry/vue'
import axios from 'axios'

const route = useRoute()
const router = useRouter()

const email = ref('')
const password = ref('')
const confirmPassword = ref('')
const errorMessage = ref('')
const loading = ref(false)
const passwordResetSuccessfully = ref(false)

const resetPassword = async () => {
  if (password.value !== confirmPassword.value) {
    errorMessage.value = 'password don`t match'
    return
  }
  try {
    loading.value = true

    await axios.get(`${import.meta.env.VITE_LOOKALIKES_API}/sanctum/csrf-cookie`)

    const res = await axios.post(`${import.meta.env.VITE_LOOKALIKES_API}/reset-password`, {
      email: email.value,
      password: password.value,
      password_confirmation: confirmPassword.value,
      token: route.query.token
    })

    passwordResetSuccessfully.value = true
  } catch (error) {
    captureException(error)
    errorMessage.value = 'Oops,something went wrong. please contact support'
  } finally {
    loading.value = false
  }
}

const redirectToLogin = () => {
  router.push({ name: 'login' })
}
</script>

<style scoped>
.card-shadow {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
</style>
