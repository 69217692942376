<template>
  <div class="flex flex-1">
    <table class="w-full">
      <thead class="sticky top-0 bg-gray-50 z-10">
        <th
          v-for="header in table.getFlatHeaders()"
          v-bind="$attrs"
          :key="header.id"
          :class="{
            'cursor-pointer select-none': header.column.getCanSort(),
            [headerCellPadding]: headerCellPaddingClass
          }"
          class="text-left text-xxs uppercase tracking-wide text-gray-500"
          @click="($event) => header.column.getToggleSortingHandler()?.($event)"
        >
          <FlexRender
            v-if="!header.isPlaceholder"
            :render="header.column.columnDef.header"
            :props="header.getContext()"
          />
        </th>
      </thead>

      <tbody>
        <tr
          v-for="row in table.getRowModel().rows"
          :key="row.id"
          class="hover:bg-gray-50 cursor-pointer"
        >
          <td
            v-for="cell in row.getVisibleCells()"
            :key="cell.id"
            class="border-t border-gray-100 text-left text-gray-500 inter-medium text-sm"
            @click="$emit('cellClicked', cell)"
          >
            <FlexRender :render="cell.column.columnDef.cell" :props="cell.getContext()" />
          </td>
        </tr>
      </tbody>
    </table>

    <div v-if="loading" class="absolute top-0 flex h-full w-full justify-center bg-gray-550">
      <Loader class="mt-8 mb-8" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, defineProps } from 'vue'

import { FlexRender } from '@tanstack/vue-table'
import type { Table } from '@tanstack/vue-table'

import Loader from '@/components/Loader.vue'

interface IProps {
  table?: Table<Record<string, unknown>>
  loading?: boolean
  headerCellPaddingClass?: string
}

const props = defineProps<IProps>()

const headerCellPadding = computed(() => props.headerCellPaddingClass ?? 'p-4')
</script>
