<template>
  <ChartCardWithHeader
    title="Position Demand Trend Over Time"
    :show-info="showDataExplanation"
    explanation="Position Hiring Demand is charted over time to reveal seasonality or trends."
  >
    <template #graph>
      <AreaChart
        :series="series[0].data.length > 0 ? series : mockSeries"
        :categories="series[0].data.length > 0 ? positionDemandHistoryDates : mockCategories"
        :data-value-formatter="formatter"
        :mock-data="series[0].data.length === 0"
        no-data-message="Data is currently not available for this candidate in this specific location."
      />
    </template>
  </ChartCardWithHeader>
</template>

<script lang="ts" setup>
import ChartCardWithHeader from '@/components/Cards/ChartCards/ChartCardWithHeader.vue'
import AreaChart from '@/components/Charts/AreaChart.vue'
import { getLastXMonths } from '../../../../../utils'

interface IProps {
  rawSeries: { position_demand: number; timestamp: Date }[]
  rawCategories: { position_demand: number; timestamp: Date }[]
  showDataExplanation?: boolean
}

const props = defineProps<IProps>()

const mockSeries = [
  {
    name: 'Score',
    data: [20, 18, 15, 12, 13, 14, 15, 15, 16, 14, 13, 12, 11, 10, 8]
  }
]

const series = [
  {
    name: 'Score',
    data: props.rawSeries.map((data) => data.position_demand)
  }
]

const positionDemandHistoryDates =
  series[0].data.length > 0
    ? props.rawCategories.map((data) => {
        const date = new Date(data.timestamp)

        return `${date.toLocaleString('default', { month: 'short' })} ${date.getFullYear().toString().substring(2)}`
      })
    : null

const mockCategories = getLastXMonths(15)

const formatter = (val: number): string => `${val}%`
</script>
