<template>
  <component :is="layout"></component>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout.vue'
import NoLayout from '@/layouts/NoLayout.vue'
import AuthLayout from '@/layouts/AuthLayout.vue'

const route = useRoute()
const layout = computed(() => {
  if (route.meta.layout === 'default') {
    return DefaultLayout
  }
  if (route.meta.layout === 'auth') {
    return AuthLayout
  }

  return NoLayout
})
</script>
