<template>
  <BaseCard class="max-w-[448px] card-shadow m-auto">
    <template #main>
      <div class="p-4">
        <p class="inter-regular text-gray-500 text-sm mb-5">
          Forgot your password? No problem. Just let us know your email address and we will email
          you a password reset link that will allow you to choose a new one.
        </p>

        <form @submit.prevent="forgotPassword">
          <p v-if="resetLinkSent" class="text-green-600 text-sm mb-4">
            We have emailed your password reset link!
          </p>
          <p v-if="resetEmailError" class="text-red-600 text-sm mb-4">
            {{ resetEmailErrorMessage }}
          </p>
          <Input
            type="email"
            label="Email"
            label-size="small"
            @input-change="(value) => (email = value)"
            required
          />
          <BaseButton
            class="ml-auto mt-8"
            text="EMAIL PASSWORD RESET"
            type="submit"
            :loading="loading"
          />
        </form>
      </div>
    </template>
  </BaseCard>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import BaseCard from '@/components/BaseCard.vue'
import Input from '@/components/inputs/Input.vue'
import BaseButton from '@/components/BaseButton.vue'

import { captureException } from '@sentry/vue'
import axios from 'axios'

const email = ref('')
const loading = ref(false)
const resetEmailErrorMessage = ref()
const resetEmailError = ref(false)
const resetLinkSent = ref(false)

const forgotPassword = async () => {
  try {
    loading.value = true
    resetEmailErrorMessage.value = null
    resetEmailError.value = false
    resetLinkSent.value = false

    await axios.get(`${import.meta.env.VITE_LOOKALIKES_API}/sanctum/csrf-cookie`)

    const res = await axios.post(`${import.meta.env.VITE_LOOKALIKES_API}/forgot-password`, {
      email: email.value
    })
    resetLinkSent.value = true
  } catch (error) {
    captureException(error)

    resetEmailErrorMessage.value = error.response.status === 422 ? error.response.data.message : 'Oops,something went wrong. please contact support'
    resetEmailError.value = true
  } finally {
    loading.value = false
  }
}
</script>

<style scoped>
.card-shadow {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
</style>
