<template>
  <div>
    <div
      v-if="props.candidateData && props.candidateData.length > 0"
      class="flex justify-end py-2 lg:mr-[50px]"
    >
      <ShortlistTableFilter :table="table" @filter-table="table.setPageIndex(0)" />
    </div>
    <div class="max-h-[72vh] overflow-scroll overflow-x-hidden mt-2">
      <BaseTableClientSide
        v-if="props.candidateData && props.candidateData.length > 0"
        :table="table"
        header-cell-padding-class="px-4 py-2"
        :no-data="props.candidateData && props.candidateData.length === 0"
        no-data-message="No Lookalikes shortlisted for this Archetype yet. To shortlist a Lookalike, use the Shortlist button in the All Lookalikes results tab."
        @cell-clicked="viewCandidate"
      />
    </div>
    <div
      v-if="props.candidateData && props.candidateData.length === 0"
      class="flex flex-col items-center justify-center py-8 px-6"
    >
      <NovaTableIcon />

      <slot></slot>
    </div>
    <Pagination v-if="props.candidateData && props.candidateData.length > 0" :table="table" />
  </div>
</template>

<script lang="tsx" setup>
import { defineEmits, defineProps, ref, withModifiers } from 'vue'
import { useRouter } from 'vue-router'

// Third-Party Libraries
import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  useVueTable
} from '@tanstack/vue-table'
import { download, generateCsv, mkConfig } from 'export-to-csv'

// Utilities and Helpers
import { convertMonthsToYearsMonths } from '@/utils/date'
import { locationShortenFormat } from '@/utils/location-formatter'

// Store
import { useLookalikesStore } from '@/stores/lookalikes'

// Components
import BaseTableClientSide from '@/components/table/BaseTableClientSide.vue'
import BaseLinkButton from '@/components/BaseLinkButton.vue'
import BaseButton from '@/components/BaseButton.vue'
import ColumnSort from '@/components/table/ColumnSort.vue'
import NovaTableIcon from '@/components/icons/NovaTableIcon.vue'
import FileDownloadIcon from '@/components/icons/FileDownloadIcon.vue'
import CompanyLogo from '@/components/CompanyLogo.vue'
import LinkedinIcon from '@/components/icons/LinkedinIcon.vue'
import ShortlistTableFilter from '@/views/Shortlist/ShortlistTableFilter.vue'
import Pagination from '@/components/table/Pagination.vue'

interface IShortlistedLookalikesData {
  archetypeProfileId: string
  candidateProfileId: string
  searchId: string
  candidateId: number
  rank: number
  name: string
  position: string
  employer: string
  education: string
  country: string
  state: string
  city: string
  roleExp: number
  careerExp: number
  industry: string
  companyLiUrl: string
  companyLogo: string
  companyName: string
  shortlistId: number
}

interface IShortlistedLookalikesTable {
  candidateData: Record<string, any>[]
  archetypeName: string
  loading?: boolean
  jobTitle: string
}

const emit = defineEmits(['shortlistSucceed', 'shortlistFailed', 'deleteShortList'])

const props = defineProps<IShortlistedLookalikesTable>()
const store = useLookalikesStore()
const router = useRouter()
const notClickableCells = ref(['view', 'searchResults', 'remove'])

const columnHelper = createColumnHelper<IShortlistedLookalikesData>()

const columns = [
  columnHelper.accessor('rank', {
    id: 'rank',
    header: 'rank',
    size: 60,
    cell: (prop) => <span class={'block py-4 pr-2 pl-4'}>{prop.getValue()}</span>
  }),
  columnHelper.accessor('name', {
    header: 'name',
    size: 115,
    cell: (prop) => (
      <a
        class={'flex'}
        href={`https://www.linkedin.com/in/${prop.row.original.candidateProfileId}`}
        target="_blank"
        rel="noreferrer"
        onClick={withModifiers(() => {}, ['stop'])}
      >
        <LinkedinIcon class={'h-4 w-4 self-center'} />
        <span class={'block py-4 pl-2 pr-4'}>{prop.getValue()}</span>
      </a>
    )
  }),
  columnHelper.accessor('position', {
    header: 'position',
    size: 180,
    cell: (prop) => <span class={'block p-4'}>{prop.getValue()}</span>
  }),
  columnHelper.accessor('employer', {
    header: 'employer',
    size: 100,
    cell: (prop) => (
      <div class="w-24 px-4 justify-center items-center flex py-3">
        <CompanyLogo
          src={prop.row.original.companyLogo}
          company-name={prop.row.original.companyName}
          link={prop.row.original.companyLiUrl}
        />
      </div>
    )
  }),
  columnHelper.accessor('industry', {
    header: 'industry',
    size: 180,
    cell: (prop) => <span class={'block p-4'}>{prop.getValue()}</span>
  }),
  columnHelper.accessor('country', {
    header: 'country',
    filterFn: 'arrIncludesSome'
  }),
  columnHelper.accessor('state', {
    header: 'state',
    filterFn: 'arrIncludesSome'
  }),
  columnHelper.accessor('city', {
    header: 'city',
    filterFn: 'arrIncludesSome'
  }),
  columnHelper.display({
    id: 'location',
    header: 'location',
    size: 180,
    cell: (prop) => (
      <span class={'block p-4'}>
        {locationShortenFormat(
          prop.row.original.country,
          prop.row.original.state,
          prop.row.original.city,
          true
        )}
      </span>
    )
  }),
  columnHelper.accessor('roleExp', {
    id: 'roleExp',
    header: () => <ColumnSort title={'Total Role Exp'} />,
    size: 135,
    cell: (prop) => (
      <span class={'block p-4'}>
        {prop.getValue() !== null ? convertMonthsToYearsMonths(prop.getValue()) : 'N\\A'}
      </span>
    ),
    enableSorting: true
  }),
  columnHelper.accessor('careerExp', {
    id: 'careerExp',
    size: 110,
    header: () => <ColumnSort title={'Career Exp'} />,
    cell: (prop) => (
      <span class={'block p-4'}>
        {prop.getValue() !== null ? convertMonthsToYearsMonths(prop.getValue()) : 'N\\A'}
      </span>
    ),
    enableSorting: true
  }),

  columnHelper.display({
    id: 'view',
    size: 47,
    cell: (prop) => (
      <BaseLinkButton
        text={'View'}
        target={'_blank'}
        link={
          `/search-results/${prop.row.original.searchId}/candidate/${prop.row.original.candidateId}` +
          (store.shortlist.includes(prop.row.original.candidateProfileId)
            ? '?shortlisted=true'
            : '')
        }
      />
    )
  }),
  columnHelper.display({
    id: 'searchResults',

    cell: (prop) => (
      <BaseLinkButton
        text={'Search Results'}
        target={'_blank'}
        link={`/search-results/${prop.row.original.searchId}`}
      />
    )
  }),
  columnHelper.display({
    id: 'remove',
    size: 100,
    header: () => (
      <BaseButton
        class={'text-xxs ml-[-15px]'}
        bg-color={'green'}
        height={'h-6'}
        text="Export"
        onClick={() => exportCsv()}
      >
        <FileDownloadIcon class={'ml-2 w-3'} />
      </BaseButton>
    ),
    cell: (prop) => (
      <BaseButton
        text={'Remove'}
        bgColor={'red'}
        onClick={() => {
          emit('deleteShortList', prop.row.original.shortlistId)
        }}
      />
    )
  })
]

const tableConfig = ref({
  get data() {
    return props.candidateData
  },
  initialState: {
    sorting: [
      {
        id: 'rank',
        desc: false // sort by name in descending order by default
      }
    ],
    pagination: {
      pageIndex: 0, //custom initial page index
      pageSize: 25 //custom default page size
    },
    columnVisibility: {
      country: false,
      state: false,
      city: false
    }
  },
  columns: columns,
  getCoreRowModel: getCoreRowModel(),
  getSortedRowModel: getSortedRowModel(),
  getFilteredRowModel: getFilteredRowModel(), //client-side filtering
  getFacetedUniqueValues: getFacetedUniqueValues(), // generate unique values for select filter/autocomplete
  getPaginationRowModel: getPaginationRowModel(),
  autoResetPageIndex: false
})

const viewCandidate = (data) => {
  if (!notClickableCells.value.includes(data.column.id)) {
    window.open(
      router.resolve({
        name: 'candidate',
        params: {
          searchId: data.row. original.searchId,
          candidateId: data.row.original.candidateId
        }
      }).href,
      '_blank'
    )
  }
}

const table = ref(useVueTable(tableConfig.value))

const exportCsv = () => {
  const data = table.value.getPrePaginationRowModel().flatRows.map((row) => {
    return {
      Name: row.original.name,
      Position: row.original.position,
      Employer: row.original.employer,
      Industry: row.original.industry,
      Location: locationShortenFormat(
        row.original.country,
        row.original.state,
        row.original.city,
        true
      ),
      'Total Role Exp':
        row.original.roleExp !== null ? convertMonthsToYearsMonths(row.original.roleExp) : 'N\\A',
      'Career Exp':
        row.original.careerExp !== null
          ? convertMonthsToYearsMonths(row.original.careerExp)
          : 'N\\A',
      'Linkedin Profile URL': `https://www.linkedin.com/in/${row.original.candidateProfileId}`
    }
  })

  const csvConfig = mkConfig({
    useKeysAsHeaders: true,
    filename: `lookalikes_search_results_shortlisted_${props.jobTitle}_${new Date().toLocaleDateString()}`
  })
  const csv = generateCsv(csvConfig)(data)
  download(csvConfig)(csv)
}
</script>
