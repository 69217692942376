<template>
  <Input
    class="mb-2"
    :label="title"
    :type="showPassword ? 'text' : 'password'"
    :name="name"
    :value="value"
    :required="required"
    :disabled="disabled"
    :placeholder="placeholder"
    :minlength="minLength"
    :maxLength="maxLength"
    :label-size="labelSize"
    @input="$emit('inputChange', $event.target.value)"
  >
    <div class="relative">
      <div class="absolute cursor-pointer right-[10px] top-[-30px]" @click="showPassword = !showPassword">
        <EyeIcon v-if="!showPassword"></EyeIcon>
        <EyeOffIcon v-if="showPassword"></EyeOffIcon>
      </div>
    </div>
  </Input>
  <ul v-if="validationErrors" class="mb-5">
    <li class="text-rose-600" v-for="(error, idx) in validationErrors" :key="idx">
      {{ error }}
    </li>
  </ul>
</template>

<script setup lang="ts">
import Input from '@/components/inputs/Input.vue'
import { defineEmits, defineProps, ref } from 'vue'
import EyeIcon from '@/components/icons/EyeIcon.vue'
import EyeOffIcon from '@/components/icons/EyeOffIcon.vue'

interface IProps {
  required?: boolean
  disabled?: boolean
  value?: string
  title?: string
  placeholder?: string
  name?: string | number
  minLength?: string
  maxLength?: string
  validationErrors?: string[]
  labelSize?: string
}

const showPassword = ref(false)


defineEmits(['inputChange'])
defineProps<IProps>()
</script>
