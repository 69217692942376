import { defineStore } from 'pinia'
import axios from 'axios'
import { captureException } from '@sentry/vue'

import type { BuildArchetypeSearch } from '@/components/BuildArchetypeSearch/interfaces'

interface IState {
  shortlist: string[]
  lookalikeCurrentHistorySearchId: number | null
  searchResults: Record<string, any>[] | null
  archetypeProfile: Record<string, any>[] | null
  removedExpItems: string[]
  countries: string[]
  states: string[]
  cities: string[]
  statesOptions: string[]
  citiesOptions: string[]
  archetypeLiProfileUrl: string
  buildArchetype: BuildArchetypeSearch | null
}

export const useLookalikesStore = defineStore('lookalikes', {
  state: (): IState => ({
    lookalikeCurrentHistorySearchId: null,
    shortlist: [],
    searchResults: null,
    removedExpItems: [],
    countries: [],
    states: [],
    cities: [],
    statesOptions: [],
    citiesOptions: [],
    archetypeProfile: null,
    archetypeLiProfileUrl: '',
    buildArchetype: null
  }),

  actions: {
    async fetchSearchResults(searchId: number) {
      try {
        const res = await axios.get(
          `${import.meta.env.VITE_LOOKALIKES_API}/api/lookalikes/search/${searchId}`
        )

        if (res.data.searchType === 'lookalike') {
          this.setLookalikesSearchResults(res.data)
        }

        if (res.data.searchType === 'build_archetype') {
          this.setBuildArchetypeSearchResults(res.data)
        }
      } catch (err) {
        captureException(err)
        throw err
      }
    },

    setLookalikesSearchResults(data) {
      this.setSearchResults(data)
      this.setArchetypeProfile(data.archetype_profile_data_for_search)
      this.setRemovedExpItems(data.archetypeData.deletedExpItems)
      this.setArchetypeLiProfileUrl(`https://www.linkedin.com/in/${data.archetypeData.profileId}`)
      this.setCountries(data.filters.countries)
      this.setStates(data.filters.states)
      this.setCities(data.filters.cities)
    },

    setBuildArchetypeSearchResults(data) {
      this.setSearchResults(data)
      this.setBuildArchetype(data.archetypeData)
      this.setCountries(data.filters.countries)
      this.setStates(data.filters.states)
      this.setCities(data.filters.cities)
    },

    populateShortlist() {
      if (this.searchResults) {
        this.resetShortlist()
        this.searchResults.candidateData.forEach((candidate) => {
          if (candidate.shortlisted) {
            this.addCandidateLiIdToShortlist(candidate.candidateProfileId)
          }
        })
      }
    },

    setSearchResults(data: Record<string, any>[]) {
      this.searchResults = data

      this.populateShortlist()
    },

    setArchetypeProfile(archetypeProfile: Record<string, any>[]) {
      this.archetypeProfile = archetypeProfile
    },
    setBuildArchetype(payload: BuildArchetypeSearch) {
      this.buildArchetype = payload
    },
    setLookalikeCurrentHistorySearchId(searchId: number) {
      this.lookalikeCurrentHistorySearchId = searchId
    },
    setRemovedExpItems(removedExpItems: string[]) {
      this.removedExpItems = removedExpItems
    },
    setArchetypeLiProfileUrl(liProfileUrl: string) {
      this.archetypeLiProfileUrl = liProfileUrl
    },
    setCountries(countries: string[]) {
      this.countries = countries
    },
    setStates(states: string[]) {
      this.states = states
    },
    setCities(cities: string[]) {
      this.cities = cities
    },
    setStatesOptions(statesOptions: string[]) {
      this.statesOptions = statesOptions
    },
    setCitiesOptions(citiesOptions: string[]) {
      this.citiesOptions = citiesOptions
    },
    setArchetypesTotalCarerExp(careerExp: number) {
      this.archetypeProfile.archetype_total_career_experience = careerExp
    },
    setArchetypesTotalPositionExp(totalPositionsExp: number) {
      this.archetypeProfile.archetype_total_experience_in_position = totalPositionsExp
    },
    addCandidateLiIdToShortlist(candidateLiProfileId: string) {
      this.shortlist.push(candidateLiProfileId)
    },
    resetShortlist() {
      this.shortlist = []
    },
    resetRemovedExpItems() {
      this.removedExpItems = []
    },
    removeCandidateLiIdFromShortlist(candidateLiProfileId: string) {
      this.shortlist = this.shortlist.filter((shortlistedCandidateLiProfileId) => {
        return shortlistedCandidateLiProfileId !== candidateLiProfileId
      })
    }
  }
})
