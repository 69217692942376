<template>
  <BaseInput :title="label" :label-id="label" label-size="small">
    <template #default>
      <div class="flex gap-2">
        <input
          class="border rounded px-3 h-9 w-28 inter-medium text-gray-500 text-sm"
          type="number"
          :form="form"
          :name="name"
          v-model="localMinValue"
          :min="minInputMinValid"
          :max="minInputMaxValid"
          :required="required"
          :disabled="disabled"
          :placeholder="minPlaceholder"
          @keyup="negativeCheck($event)"
          @click="negativeCheck($event)"
          @input="$emit('minValueChange', $event.target.value)"
        />
        <input
          class="border rounded px-3 h-9 w-28 inter-medium text-gray-500 text-sm"
          type="number"
          :form="form"
          :name="name"
          :min="localMinValue ?? 0"
          :max="maxInputMaxValid"
          v-model="localMaxValue"
          :required="required"
          :disabled="disabled"
          :placeholder="maxPlaceholder"
          @keyup="negativeCheck($event, maxValue, localMinValue, maxInputMaxValid)"
          @click="negativeCheck($event, maxValue, localMinValue, maxInputMaxValid)"
          @input="$emit('maxValueChange', $event.target.value)"
        />
        <CloseButton class="relative top-[13px] right-1 h-2 w-2" @close="clear"></CloseButton>
      </div>
    </template>
  </BaseInput>
</template>

<script setup lang="ts">
import { defineEmits, defineProps, ref } from 'vue'

import BaseInput from './BaseInput.vue'
import CloseButton from '@/components/CloseButton.vue'

interface IProps {
  label?: string
  required?: boolean
  disabled?: boolean
  minValue?: number
  maxValue?: number
  minInputMinValid?: string
  minInputMaxValid?: string
  maxInputMinValid?: string
  maxInputMaxValid?: string
  minPlaceholder?: string
  maxPlaceholder?: string
  name?: string | number
  form?: string
}

const emit = defineEmits(['minValueChange', 'maxValueChange'])
const props = defineProps<IProps>()
const localMinValue = ref(props.minValue)
const localMaxValue = ref(props.maxValue)

function negativeCheck(event: never) {
  if (event.target.value < 0) {
    event.target.value = Math.abs(event.target.value)
  }
}

function clear(): void {
  if (localMinValue.value || localMaxValue.value) {
    localMinValue.value = undefined
    localMaxValue.value = undefined
    emit('minValueChange', undefined)
    emit('maxValueChange', undefined)
  }
}

defineExpose({ clear });
</script>
