<template>
  <div class="relative">
    <div @mouseover="displayToolTip = true" @mouseleave="displayToolTip = false">
      <slot></slot>
    </div>
    <p
      v-if="displayToolTip"
      class="absolute z-10 rounded bg-black p-2 text-white"
      :class="cssClasses"
    >
      {{ text }}
    </p>
  </div>
</template>

<script lang="ts" setup>
import { defineProps, ref } from 'vue'

interface IProps {
  text: string
  cssClasses?: string
}

defineProps<IProps>()

const displayToolTip = ref(false)
</script>
