<template>
  <BaseTab class="rounded-b-lg bg-white px-5 py-2 drop-shadow-md">
    <div>
      <LeftArrowIcon class="cursor-pointer mb-4" @click="goBack" />
      <SelectInput
        v-if="showShortlistOptions"
        class="w-[40%]"
        :api-url="shortlistListApiUrl"
        title="Select a role from the menu to view all the candidates you have shortlisted for that role"
        @options="updateShortlistOptionsVisibility"
        @option-selected="getShortlist"
      />
      <ShortlistTable
        :candidate-data="selectedShortlist"
        :job-title="jobTitle"
        @delete-short-list="deleteShortList"
      >
        <template #default>
          <p
            v-if="!showShortlistOptions"
            class="mt-3 mb-3 max-w-[50%] text-base inter-medium text-gray-500"
          >
            No Shortlists created yet. Add new Shortlists and candidates by clicking the Shortlist
            button for candidates you want to save.
          </p>
        </template>
      </ShortlistTable>
    </div>
  </BaseTab>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import axios from 'axios'

import BaseTab from '@/components/BaseTab.vue'
import ShortlistTable from './ShortlistTable.vue'
import SelectInput from '@/components/inputs/SelectInput.vue'
import LeftArrowIcon from '@/components/icons/LeftArrowIcon.vue'

const router = useRouter()

const selectedShortlist = ref([])
const jobTitle = ref('')
const showShortlistOptions = ref(true)
const shortlistListApiUrl = `${import.meta.env.VITE_LOOKALIKES_API}/api/shortlist/list`

const deleteShortList = async (shortlistId: number) => {
  try {
    const res = await axios.delete(
      `${import.meta.env.VITE_LOOKALIKES_API}/api/shortlist/${shortlistId}`
    )
    selectedShortlist.value = res.data
  } catch (error) {
    if (error.response.status === 400) {
      selectedShortlist.value = []
    }
  }
}

const getShortlist = async (title: string) => {
  if (title.value === '') {
    selectedShortlist.value = []
    return
  }

  jobTitle.value = title.value
  try {
    const res = await axios.get(
      `${import.meta.env.VITE_LOOKALIKES_API}/api/shortlist/${title.value}`
    )
    selectedShortlist.value = res.data
  } catch (error) {
    console.log(error)
  }
}

const goBack = () => {
  router.go(-1)
}

const updateShortlistOptionsVisibility = (options: any) => {
  if (options.length > 0) {
    showShortlistOptions.value = true
  } else {
    showShortlistOptions.value = false
  }
}
</script>
