<template>
  <svg fill="#000000" width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g stroke-width="0"></g>
    <g stroke-linecap="round" stroke-linejoin="round"></g>
    <g>
      <path d="M17,17h4m-2-2v4" style="fill: none; stroke: #000000; stroke-linecap: round; stroke-linejoin: round; stroke-width: 2;"></path>
      <path d="M14,13.29A6.91,6.91,0,0,0,12,13H10a7,7,0,0,0-7,7,1,1,0,0,0,1,1H15" style="fill: none; stroke: #000000; stroke-linecap: round; stroke-linejoin: round; stroke-width: 2;"></path>
      <circle cx="11" cy="8" r="5" style="fill: none; stroke: #000000; stroke-linecap: round; stroke-linejoin: round; stroke-width: 2;"></circle>
    </g>
  </svg>
</template>
