<template>
  <SearchResultsTable
    v-if="store.searchResults"
    :candidate-data="store.searchResults.candidateData"
    :searched-job-title="store.buildArchetype.title"
    table-height="max-h-[78vh]"
    @shortlist-failed="$emit('shortlist-failed')"
    @shortlist-succeed="$emit('shortlist-succeed')"
  >
    <template #table-header>
      <h1
        class="capitalize mt-0 mb-0 self-center text-3xl blinker-semibold leading-9 text-slate-800 camelCase"
      >
        {{ store.buildArchetype.title }}
        · {{ convertMonthsToYearsMonths(store.buildArchetype.total_role_exp) }} (Role Exp) ·
        {{ searchedLocation }}
      </h1>
    </template>
    <template #table-header-buttons>
      <div class="flex self-baseline gap-4">
        <BaseButton
          class="self-center !mr-0"
          text="Edit Search"
          bg-color="green"
          @click="showEditModal = true"
        />
        <RouterLink
          class="black-theme-white-link bg-primary-500 hover:bg-primary-400 self-center focus:outline-acne inline-flex h-9 items-center rounded px-3 text-sm font-bold text-white shadow ring-primary-200 last:mr-0 focus:ring dark:text-gray-800 dark:ring-gray-600"
          to="/"
          >New Search</RouterLink
        >
      </div>
    </template>
  </SearchResultsTable>
  <EditBuildArchetypeSearchModal
    v-if="showEditModal"
    @close="showEditModal = false"
    @search-in-progress="$emit('stop-fetching-results')"
    @search-finished="$emit('search-finished')"
    @error="$emit('error')"
  />
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import { locationShortenFormat } from '@/utils/location-formatter'
import { convertMonthsToYearsMonths } from '@/utils/date'

import BaseButton from '@/components/BaseButton.vue'
import SearchResultsTable from '../Components/SearchResultsTable.vue'
import EditBuildArchetypeSearchModal from './EditBuildArchetypeSearchModal.vue'

import { useLookalikesStore } from '@/stores/lookalikes'

defineEmits([
  'shortlist-failed',
  'shortlist-succeed',
  'resume-fetch-results',
  'stop-fetching-results',
  'search-finished',
  'error'
])

const store = useLookalikesStore()

const showEditModal = ref(false)
const searchedLocation = computed(() => {
  if (store.countries.length > 1 || store.states.length > 1 || store.cities.length > 1) {
    return 'Multiple Locations'
  }

  return locationShortenFormat(
    store.buildArchetype?.countries[0],
    store.buildArchetype?.states[0],
    store.buildArchetype?.cities[0],
    true
  )
})
</script>
