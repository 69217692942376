<template>
  <BaseTab class="rounded-b-lg bg-white px-5 py-2 drop-shadow-md">
    <ToggleButton
      @toggle="showMoreInfo = !showMoreInfo"
      :toggle-state="false"
      label="Show Data Explanations"
    />
    <AverageOfChartCard
      :title="`${props.data.jobTitle} Tenure at Other Companies`"
      :average-value="
        benchmarksData.tenureAtOtherCompanies !== undefined
          ? benchmarksData.tenureAtOtherCompanies.avgYearsAndMonths
          : ''
      "
      :percentage-value="
        benchmarksData.tenureAtOtherCompanies !== undefined
          ? benchmarksData.tenureAtOtherCompanies.percentageChange
          : ''
      "
      :up-down-same="
        benchmarksData.tenureAtOtherCompanies !== undefined
          ? benchmarksData.tenureAtOtherCompanies.percentageChangeLabel
          : ''
      "
      :percentage-value-title="tenureAtOtherCompaniesPercentageValueTitle"
      :loading="loading"
    >
      <ColumnChartCard
        v-if="benchmarksData && benchmarksData.tenureAtOtherCompanies !== undefined"
        class="flex-1"
        :is-percentage-values="true"
        :mock-data="!benchmarksData.tenureAtOtherCompanies.categories"
        :series="
          tenureAtOtherCompaniesSeries.length > 0
            ? tenureAtOtherCompaniesSeries
            : mockSeriesTenureAtOtherCompanies
        "
        :x-axis-categories="benchmarksData.tenureAtOtherCompanies.categories ?? mockCategories"
        :x-annotation-values="[benchmarksData.tenureAtOtherCompanies.avg]"
        x-axis-title="Years of Position Tenure"
        :show-info="showMoreInfo"
        :explanation="`This chart shows the average tenure of other people in this position, combining data from both current and past employees at other companies. Our Workforce Insights Engine contains data from millions of other companies. The bar which contains ${props.data.linkedinProfileName} position tenure is purple. The average tenure of ${props.data.jobTitle} at other companies is a vertical line.`"
      ></ColumnChartCard>
    </AverageOfChartCard>

    <AverageOfChartCard
      :title="`Average Aggregate ${props.data.jobTitle} Tenure Across Multiple Companies`"
      :average-value="
        benchmarksData.averageAggregate !== undefined
          ? benchmarksData.averageAggregate.avgYearsAndMonths
          : ''
      "
      :percentage-value="
        benchmarksData.averageAggregate !== undefined
          ? benchmarksData.averageAggregate.percentageChange
          : ''
      "
      :up-down-same="
        benchmarksData.averageAggregate !== undefined
          ? benchmarksData.averageAggregate.percentageChangeLabel
          : ''
      "
      :percentage-value-title="averageAggregateTitle"
      :loading="loading"
    >
      <ColumnChartCard
        v-if="benchmarksData && benchmarksData.averageAggregate"
        class="flex-1"
        :is-percentage-values="true"
        :mock-data="!benchmarksData.averageAggregate.categories"
        :series="
          averageAggregateSeries.length > 0
            ? averageAggregateSeries
            : mockSeriesTenureAtOtherCompanies
        "
        :x-axis-categories="benchmarksData.averageAggregate.categories ?? mockCategories"
        :x-annotation-values="[benchmarksData.averageAggregate.avg]"
        x-axis-title="Aggregate years in position"
        :show-info="showMoreInfo"
        :explanation="` This chart shows the average tenure of a ${props.data.jobTitle}, combining data from current and past employees at other companies. This analysis reveals the aggregate tenure of a ${props.data.jobTitle}, including multiple successive employers. The bar which contains ${props.data.linkedinProfileName} ’s aggregate position tenure is purple. The average aggregate tenure of ${props.data.jobTitle} is a vertical line.`"
      ></ColumnChartCard>
    </AverageOfChartCard>

    <AverageOfChartCard
      :title="`Company Tenure for a ${props.data.jobTitle} at Other Companies`"
      :average-value="
        benchmarksData.companyTenureForPositionInOtherCompanies !== undefined
          ? benchmarksData.companyTenureForPositionInOtherCompanies.avgYearsAndMonths
          : ''
      "
      :percentage-value="
        benchmarksData.companyTenureForPositionInOtherCompanies !== undefined
          ? benchmarksData.companyTenureForPositionInOtherCompanies.percentageChange
          : ''
      "
      :up-down-same="
        benchmarksData.companyTenureForPositionInOtherCompanies !== undefined
          ? benchmarksData.companyTenureForPositionInOtherCompanies.percentageChangeLabel
          : ''
      "
      :percentage-value-title="companyTenureForPositionInOtherCompaniesTitle"
      :loading="loading"
    >
      <ColumnChartCard
        v-if="benchmarksData && benchmarksData.companyTenureForPositionInOtherCompanies"
        class="flex-1"
        :is-percentage-values="true"
        :mock-data="!benchmarksData.companyTenureForPositionInOtherCompanies.categories"
        :series="
          companyTenureForPositionInOtherCompaniesSeries.length > 0
            ? companyTenureForPositionInOtherCompaniesSeries
            : mockSeriesTenureAtOtherCompanies
        "
        :x-axis-categories="
          benchmarksData.companyTenureForPositionInOtherCompanies.categories ?? mockCategories
        "
        :x-annotation-values="[benchmarksData.companyTenureForPositionInOtherCompanies.avg]"
        x-axis-title="Years of company tenure"
        :show-info="showMoreInfo"
        :explanation="`This chart shows the average total company tenure for employees who are (or have been) a ${props.data.jobTitle} as part of their career path at other companies. Data is segmented in 1 year increments of total company tenure. The bar which contains ${props.data.linkedinProfileName}'s total tenure is purple. The average company tenure at other companies is a vertical line.`"
      ></ColumnChartCard>
    </AverageOfChartCard>
  </BaseTab>
</template>

<script lang="ts" setup>
import { computed, defineProps, ref } from 'vue'
import { useRoute } from 'vue-router'

import BaseTab from '@/components/BaseTab.vue'
import AverageOfChartCard from '@/components/Cards/ChartCards/AverageOfChartCard.vue'
import ColumnChartCard from '@/components/Cards/ChartCards/ColumnChartCard.vue'
import ToggleButton from '@/components/ToggleButton.vue'
import { useFetch } from '@/composables/UseFetch'

interface IProps {
  data: {
    searchId: number
    candidateProfileId: string
    linkedinProfileName: string
    jobTitle: string
  }
}

const route = useRoute()
const props = defineProps<IProps>()

const showMoreInfo = ref(false)

const {
  data: benchmarksDataFromApi,
  loading,
  error,
  errorMessage
} = useFetch(
  `${import.meta.env.VITE_LOOKALIKES_API}/api/look-alike/search/${route.params.searchId}/candidate/${route.params.candidateId}/benchmarks`
)

const benchmarksData = computed(() =>
  !loading.value && !error.value && benchmarksDataFromApi.value ? benchmarksDataFromApi.value : []
)

const tenureAtOtherCompaniesPercentageValueTitle = computed(() =>
  benchmarksDataFromApi.value
    ? comparisonLabels(benchmarksDataFromApi.value.tenureAtOtherCompanies)
    : ''
)

const averageAggregateTitle = computed(() =>
  benchmarksDataFromApi.value ? comparisonLabels(benchmarksDataFromApi.value.averageAggregate) : ''
)
const companyTenureForPositionInOtherCompaniesTitle = computed(() =>
  benchmarksDataFromApi.value
    ? comparisonLabels(benchmarksDataFromApi.value.companyTenureForPositionInOtherCompanies)
    : ''
)

const tenureAtOtherCompaniesSeries = computed(() =>
  getProportionForGraph(benchmarksDataFromApi.value.tenureAtOtherCompanies)
)
const averageAggregateSeries = computed(() =>
  getProportionForGraph(benchmarksDataFromApi.value.averageAggregate)
)
const companyTenureForPositionInOtherCompaniesSeries = computed(() =>
  getProportionForGraph(benchmarksDataFromApi.value.companyTenureForPositionInOtherCompanies)
)

const getProportionForGraph = (graphInfo) => {
  const graphData = []

  if (graphInfo && graphInfo.series) {
    graphInfo.series.forEach((element, index) => {
      if (graphInfo.selectedCategory === graphInfo.categories[index]) {
        graphData.push({
          x: graphInfo.categories[index],
          y: element,
          fillColor: '#9400FF'
        })
      } else {
        graphData.push({ x: graphInfo.categories[index], y: element })
      }
    })
    return [{ name: 'proportion', data: graphData }]
  }
  return []
}

const comparisonLabels = (graphInfo): string => {
  const upDownSame = graphInfo.percentageChangeLabel

  switch (upDownSame) {
    case 'up':
      return `Longer than the average of ${graphInfo.avgTenureYearsAndMonths.years} years ${graphInfo.avgTenureYearsAndMonths.months} months`
    case 'down':
      return `Shorter than the average of ${graphInfo.avgTenureYearsAndMonths.years} years ${graphInfo.avgTenureYearsAndMonths.months} months`
    case 'same':
      return `Same as the average of ${graphInfo.avgTenureYearsAndMonths.years} years ${graphInfo.avgTenureYearsAndMonths.months} months`
    default:
      return `Data did not load.`
  }
}

const mockCategories = [
  '0.5',
  '1.0',
  '1.5',
  '2.0',
  '2.5',
  '3.0',
  '3.5',
  '4.0',
  '4.5',
  '5.0',
  '5.5',
  '6.0',
  '6.5',
  '7.0',
  '7.5',
  '8.0',
  '8.5',
  '9.0',
  '9.5',
  '10.0+'
]

const mockSeriesTenureAtOtherCompanies = [
  {
    name: 'proportion',
    data: [1, 3, 6, 6, 6, 6, 7, 7, 7, 5, 6, 5, 6, 4, 5, 3, 4, 3, 4, 5]
  }
]
</script>
