<template>
  <div class="flex justify-center flex-1">
    <div class="p-4">
      <p>Accessing our 1B+ candidates pool...</p>
      <p v-if="displaySecondLoadingSearchMessage">
        Surfacing the best matches for your archetype...
      </p>
      <CoolLoader class="m-auto mt-8" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import CoolLoader from '@/components/CoolLoader.vue'

const displaySecondLoadingSearchMessage = ref(false)

setTimeout(() => {
  displaySecondLoadingSearchMessage.value = true
}, 4000)
</script>
