import { captureException } from '@sentry/vue';

export const encode = <T = Record<number | string | symbol, unknown>>(obj: T): string => {
    try {
        return btoa(JSON.stringify(obj));
    } catch (error) {
        console.error('Failed encoding query param');
        console.error(error);
        captureException(error);

        return '';
    }
};

export const tryDecode = <T = Record<number | string | symbol, unknown>>(stringified: string, defaultResult: T): T => {
    if (!stringified) {
        return defaultResult;
    }

    try {
        return JSON.parse(atob(stringified));
    } catch (error) {
        console.error('Failed decoding query param');
        console.error(error);
        captureException(error);

        return defaultResult;
    }
};
