<template>
  <div v-if="totalCountRows >= pageSize && totalCountRows !== 0" class="flex items-center justify-center">
    <button
      :disabled="!table.getCanPreviousPage()"
      class="relative block px-3 py-1.5 rounded text-sm hover:bg-gray-100 text-gray-500"
      @click="() => previousPage()"
    >
      Previous
    </button>

    <div class="flex text-gray-500">
      <button
        v-for="item in Math.ceil(totalCountRows / pageSize)"
        :disabled="!table.getCanNextPage() && !table.getCanPreviousPage()"
        :key="item"
        :class="`relative block px-3 py-1.5 rounded text-sm hover:bg-gray-100 ${table.getState().pagination.pageIndex === parseInt(item) - 1 ? 'bg-gray-200' : ''}`"
        @click="() => goToPage(parseInt(item) - 1)"
      >
        {{ item }}
      </button>
    </div>

    <button
      :disabled="!table.getCanNextPage()"
      class="relative block px-3 py-1.5 rounded text-sm hover:bg-gray-100 text-gray-500"
      @click="() => nextPage()"
    >
      Next
    </button>
  </div>
</template>

<script lang="ts" setup>
import { computed, defineEmits, defineProps, ref } from 'vue'

import type { Table } from '@tanstack/vue-table'

interface IProps {
  table?: Table<Record<string, unknown>>
}

const props = defineProps<IProps>()
const emit = defineEmits(['pageChange'])


const pageSize = ref(props.table.initialState.pagination.pageSize)
const totalCountRows = computed(() => props.table.getPaginationRowModel() ? Object.entries(props.table.getPaginationRowModel().rowsById).length : 0)
const goToPage = (page) => {
  props.table.setPageIndex(page)
  emit('pageChange')
}

const previousPage = () => {
  props.table.previousPage()
  emit('pageChange')
}

const nextPage = () => {
  props.table.nextPage()
  emit('pageChange')
}
</script>
