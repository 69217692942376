<template>
  <button class="hover:bg-gray-200 rounded-md h-9 text-sm p-2 flex" @click="logout">
    <LogoutIcon class="self-center mr-2" />
    Logout
  </button>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router'

import LogoutIcon from '@/components/icons/LogoutIcon.vue'
import { useUserStore } from '@/stores/user'

import axios from 'axios'

const router = useRouter()
const store = useUserStore()

const logout = async () => {
  await axios.get(`${import.meta.env.VITE_LOOKALIKES_API}/logout`)

  store.logout()

  router.push({ name: 'login' })
}
</script>
