<template>
  <div class="items-center justify-end text-right">
    <label for="toggle" class="mr-3 cursor-pointer inter-medium text-gray-500 text-sm">{{
      label
    }}</label>
    <label class="switch relative inline-block h-5 w-11 select-none align-middle">
      <input id="toggle" type="checkbox" class="h-0 w-0 opacity-0" @click="toggle" />
      <span
        class="slider round absolute top-0 left-0 right-0 bottom-0 cursor-pointer rounded-3xl duration-500 before:absolute before:left-0 before:bottom-0 before:h-5 before:w-5 before:rounded-full before:duration-500"
        :class="
          localToggleState
            ? 'bg-purple-700 before:translate-x-6 before:border before:border-purple-700 before:bg-white hover:bg-purple-800'
            : 'bg-gray-300 before:bg-gray-200 hover:bg-gray-400'
        "
      ></span>
    </label>
  </div>
</template>

<script lang="ts" setup>
import { defineEmits, defineProps, ref } from 'vue'

interface IProps {
  label: string
  toggleState: boolean
}

const emit = defineEmits(['toggle'])

const props = defineProps<IProps>()

const localToggleState = ref(props.toggleState)

const toggle = () => {
  localToggleState.value = !localToggleState.value

  emit('toggle', localToggleState.value)
}
</script>
