<template>
  <BaseCardWithHeader :no-flex="true" :title="title">
    <template #headerSection>
      <slot name="headerSection"></slot>
    </template>
    <template #main>
      <Loader v-if="loading" class="m-auto mb-4 mt-4" />
      <div v-else class="flex flex-col gap-6 p-4">
        <BaseCardWithHeader class="h-[200px]" custome-css-for-main="overflow-scroll" title="About">
          <template #main>
            <div class="p-4 text-gray-500 inter-medium text-sm">
              <p v-if="about" v-html="about"></p>
              <p v-else>About data is not available</p>
            </div>
          </template>
        </BaseCardWithHeader>
        <BaseCardWithHeader
          class="h-[500px]"
          title="Work Experience"
          custome-css-for-main="overflow-scroll"
        >
          <template #main>
            <div class="p-4 text-gray-500 inter-medium text-sm">
              <p v-if="workExperience.length === 0">Work Experience data is not available</p>

              <ul v-if="workExperience" class="list-disc">
                <template v-for="(experience, companyName) in workExp" :key="companyName">
                  <div class="mb-4">
                    <div class="flex">
                      <div class="self-center mr-4 justify-center items-center flex">
                        <CompanyLogo
                          :src="experience.positions[0].logo"
                          :company-name="companyName"
                          :link="
                            experience.positions[0].url ?? experience.positions[0].linkedin_url
                          "
                        />
                      </div>

                      <div class="pt-5">
                        <u>
                          {{ companyName }} ({{
                            experience.positions[0]
                              ? convertMonthsToYearsMonths(
                                  experience.positions[0]['total_time_in_company']
                                )
                              : ''
                          }})</u
                        >

                        <li
                          v-for="(position, idx) in experience.positions"
                          class="relative ml-6 leading-7"
                        >
                          <div
                            :class="{
                              'line-through': deletedExpItems.includes(`${companyName}.${idx}`)
                            }"
                          >
                            {{ position.title }} ({{ formatDate(position.starts_at) }} -
                            {{ formatDate(position.ends_at) ?? 'Current' }})
                          </div>
                        </li>
                      </div>
                    </div>
                  </div>
                </template>
              </ul>
            </div>
          </template>
        </BaseCardWithHeader>
        <BaseCardWithHeader
          class="h-[220px]"
          custome-css-for-main="overflow-scroll"
          title="Education"
        >
          <template #main>
            <div class="p-4 text-gray-500 inter-medium text-sm">
              <p v-if="education.length === 0">Education data is not available</p>
              <ul v-if="education" class="list-disc pl-4">
                <template v-for="(educationItem, index) in education">
                  <li
                    v-if="educationItem.field_of_study && educationItem.degree"
                    :key="index"
                    class="relative leading-7"
                  >
                    <div>
                      {{ educationItem.degree }} in {{ educationItem.field_of_study }}
                      {{ educationItem.school ? ', ' + educationItem.school : '' }}
                    </div>
                  </li>
                </template>
              </ul>
            </div>
          </template>
        </BaseCardWithHeader>
        <BaseCardWithHeader
          class="h-[400px]"
          custome-css-for-main="overflow-scroll"
          title="Current Employer"
        >
          <template #main>
            <div class="p-4 text-gray-500 inter-medium text-sm w-[100%]">
              <p v-if="!company">Company data is not available</p>

              <ul v-else-if="company && !company.ends_at" class="list-disc">
                <div class="w-28 px-4 float-end relative z-10">
                  <CompanyLogo
                    :src="company.logo"
                    :company-name="company.company ?? company.company_name"
                    :link="company.url ?? company.linkedin_url"
                    :logo-width="'w-20'"
                    :logo-height="'h-20'"
                  />
                </div>
                <u>{{ company.company ?? company.company_name }}</u>
                <li class="relative ml-6 leading-7">
                  Location:
                  {{ company.company_city ? company.company_city + ', ' : '' }}
                  {{ company.company_state ? company.company_state + ', ' : '' }}
                  {{ company.company_country }}

                  {{
                    !company.company_city && !company.company_state && !company.company_country
                      ? 'N\\A'
                      : ''
                  }}
                </li>

                <li class="relative ml-6 leading-7">Industry: {{ company.industry ?? 'N\\A' }}</li>

                <li class="relative ml-6 leading-7">
                  Company Size:
                  {{ company.company_size ? companySizeFormatted(company.company_size) : 'N\\A' }}
                </li>

                <li class="relative ml-6 leading-7">
                  Description: {{ company.company_description ?? 'N\\A' }}
                </li>
              </ul>
            </div>
          </template>
        </BaseCardWithHeader>
      </div>
    </template>
  </BaseCardWithHeader>
</template>

<script setup lang="ts">
import { computed, defineProps } from 'vue'

import BaseCardWithHeader from '@/components/Cards/BaseCards/BaseCardWithHeader.vue'
import { convertMonthsToYearsMonths } from '../../../../utils'
import Loader from '@/components/Loader.vue'
import CompanyLogo from '@/components/CompanyLogo.vue'
import { useLookalikesStore } from '@/stores/lookalikes'

interface IProps {
  about: string
  workExperience: {}[]
  education: {}[]
  title: string
  loading: boolean
}

const props = defineProps<IProps>()
const store = useLookalikesStore()
const deletedExpItems = computed(() => store.removedExpItems)

const workExp = computed(() => {
  if (props.workExperience) {
    return props.workExperience.reduce((acc, cur) => {
      if (acc[cur.company] !== undefined) {
        acc[cur.company]['positions'].push(cur)
      } else {
        acc[cur.company] = { positions: [cur] }
      }
      return acc
    }, {})
  }

  return {}
})

const companySizeRange = [1, 2, 10, 50, 200, 500, 1000, 5000, 10000]

const company = computed(() => {
  if (props.workExperience.length > 0) {
    return props.workExperience[0]
  }

  return {}
})

const formatDate = (date) => {
  if (date === null) {
    return null
  }
  const dateObj = new Date(date)

  return `${dateObj.toLocaleString('default', { month: 'short' })} ${dateObj.getFullYear()}`
}

const companySizeFormatted = (companySize: number) => {
  const rangeIndex = companySizeRange.indexOf(companySize)

  if (companySizeRange[rangeIndex + 1] == undefined) return `${companySize.toLocaleString()}+`

  return `${companySizeRange[rangeIndex].toLocaleString()} - ${companySizeRange[rangeIndex + 1].toLocaleString()}`
}
</script>
