<template>
  <div class="relative h-96 flex-1">
    <VueApexCharts :options="chartOptions" :series="series" height="100%" />
    <div
      v-if="mockData"
      class="absolute top-0 z-[99] flex h-full w-full items-center justify-center bg-white/50"
    >
      <p class="text-center text-xl text-black">
        Oops! It appears we are still crunching the data for this chart.
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { defineProps, ref } from 'vue'
import VueApexCharts from 'vue3-apexcharts'
import type { VueApexChartsComponent } from 'vue3-apexcharts'

interface IProps {
  series: VueApexChartsComponent['series']
  xAxisCategories: number[] | string[]
  yAnnotationValues?: number[]
  xAnnotationValues?: number | string[]
  yAnnotationTitles?: string[]
  xAnnotationTitles?: string[]
  xAxisTitle?: string
  isPercentageValues?: boolean
  mockData?: boolean
}

const props = defineProps<IProps>()

const truncateCategory = (
  category: string[] | string,
  timestamp: number,
  config: unknown
): string[] | string => {
  // No config, means tooltip context, no need to truncate the category
  if (!config && typeof timestamp === 'object' && 'w' in timestamp) {
    return category
  }

  const joinedCategory = typeof category === 'string' ? category : category.join(' ')

  // Split by ' ' (Space)
  // But concat small words to the previous word if possible (less than 4 letters like VP or etc)
  const splittedCategory = joinedCategory.split(' ').reduce<string[]>((words, word) => {
    // If it's the first word
    // OR any word bigger than 4 letters
    // OR previous word is too big to concat into it
    // keep it as a word on its own
    if (word.length > 4 || words.length === 0 || words[words.length - 1].length > 10) {
      words.push(word)
    } else {
      words[words.length - 1] = `${words[words.length - 1]} ${word}`
    }

    return words
  }, [])

  if (splittedCategory.length <= 5) {
    return splittedCategory
  }

  const top5WordsInCategory = splittedCategory.slice(0, 5)
  const lastWord = top5WordsInCategory[top5WordsInCategory.length - 1]

  // Take the last word and append "..." to it and make sure it's truncated to 7 letters
  top5WordsInCategory[top5WordsInCategory.length - 1] = `${lastWord.slice(0, 7)}...`

  return top5WordsInCategory
}

const chartOptions = ref({
  chart: {
    type: 'bar',
    toolbar: {
      tools: {
        download: false
      }
    }
  },
  labels: props.xAxisCategories,
  colors: ['#008FFB', '#fb923cd9'],
  dataLabels: {
    enabled: false
  },
  xaxis: {
    title: {
      text: props.xAxisTitle
    },
    labels: {
      rotate: 0,
      formatter: truncateCategory
    }
  },

  yaxis: {
    decimalsInFloat: 0,
    labels: {
      formatter(val: number | string): string {
        if (typeof val === 'number' && props.isPercentageValues) {
          return `${Math.round(val)}%`
        }

        return val as string
      }
    }
  },
  annotations: {
    yaxis: [
      props.yAnnotationValues?.[0]
        ? {
            label: {
              offsetX: 20,
              position: 'left',
              text: props.yAnnotationTitles?.[0] ?? 'AVG',
              borderColor: 'transparent',
              style: {
                color: '#fff',
                background: 'black',
                padding: {
                  bottom: 4
                }
              }
            },
            y: props.yAnnotationValues[0],
            strokeDashArray: 10,
            borderWidth: 2,
            borderColor: 'black'
          }
        : {},
      props.yAnnotationValues?.[1]
        ? {
            label: {
              offsetX: 20,
              position: 'left',
              text: props.yAnnotationTitles?.[1] ?? 'AVG',
              style: {
                color: '#fff',
                background: 'orange',
                padding: {
                  bottom: 4
                }
              }
            },
            y: props.yAnnotationValues[1],
            strokeDashArray: 10,
            borderWidth: 2,
            borderColor: 'orange'
          }
        : {}
    ],
    xaxis: props.xAnnotationValues
      ? [
          props.xAnnotationValues?.[0]
            ? {
                label: {
                  text: props.xAnnotationTitles?.[0] ?? 'AVG',
                  style: {
                    color: '#fff',
                    background: 'black'
                  }
                },
                x: props.xAnnotationValues[0].toString(),
                strokeDashArray: 10,
                borderColor: 'black',
                borderWidth: 2
              }
            : {},
          props.xAnnotationValues?.[1]
            ? {
                label: {
                  text: props.xAnnotationTitles?.[1] ?? 'AVG',
                  style: {
                    color: '#fff',
                    background: 'orange'
                  }
                },
                x: props.xAnnotationValues[1].toString(),
                strokeDashArray: 10,
                borderColor: 'orange',
                borderWidth: 2
              }
            : { borderColor: 'transparent' }
        ]
      : []
  }
})
</script>
