<template>
  <div class="mt-2 flex">
    <div>
      <ArrowUpIcon v-if="upDownSame === 'up'" class="h-4 w-4 text-green-600" />
      <ArrowDownIcon v-if="upDownSame === 'down'" class="h-4 w-4 text-red-700" />
    </div>
    <span v-if="upDownSame" class="ml-1 text-xs inter-medium text-gray-500">
      <span v-if="percentageValue && upDownSame !== 'same'">{{ absValueOfPercentageValue }}%</span>
      {{ percentageValueTitle }}</span
    >
  </div>
</template>

<script setup lang="ts">
import { computed, defineProps } from 'vue'
import ArrowUpIcon from '@/components/icons/ArrowUpIcon.vue'
import ArrowDownIcon from '@/components/icons/ArrowDownIcon.vue'

import type { UpDownSame } from './interfaces'

interface IProps {
  percentageValue?: number
  percentageValueTitle?: string
  upDownSame?: UpDownSame
}

const props = defineProps<IProps>()

const absValueOfPercentageValue = computed(() =>
  props.percentageValue ? Math.round(Math.abs(props.percentageValue)) : undefined
)
</script>
