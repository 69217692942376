<template>
  <table
    v-if="archetypeData"
    class="ml-[56px] w-[82%] border-separate border-spacing-0 self-end rounded border border-solid border-slate-200"
  >
    <tr>
      <th
        class="border-r-solid border-r border-r-slate-200 pl-[7px] pr-[7px] text-left text-[11px] inter-bold text-gray-500"
      >
        ARCHETYPE
      </th>
      <th
        class="border-r-solid border-r border-r-slate-200 pl-[7px] pr-[7px] text-left text-[11px] inter-bold text-gray-500"
      >
        POSITION {{ archetypeData.revisedPosition ? ' (OVERRIDE)' : '' }}
      </th>
      <th
        class="border-r-solid border-r border-r-slate-200 pl-[7px] pr-[7px] text-left text-[11px] inter-bold text-gray-500"
      >
        EMPLOYER
      </th>
      <th
        class="border-r-solid border-r border-r-slate-200 pl-[7px] pr-[7px] text-left text-[11px] inter-bold text-gray-500"
      >
        INDUSTRY
      </th>
      <!-- <th
                            class="border-r-solid border-r border-r-slate-200 pl-[7px] pr-[7px] text-left text-[11px]"
                        >
                            EDUCATION
                        </th> -->
      <th
        class="border-r-solid border-r border-r-slate-200 pl-[7px] pr-[7px] text-left text-[11px] inter-bold text-gray-500"
      >
        LOCATION
      </th>
      <th
        class="border-r-solid border-r border-r-slate-200 pl-[7px] pr-[7px] text-left text-[11px] inter-bold text-gray-500"
      >
        TOTAL ROLE EXP {{ archetypeData.revizedTotalRoleExp ? ' (OVERRIDE)' : '' }}
      </th>
      <th class="pl-[7px] pr-[7px] text-left text-[11px] inter-bold text-gray-500">
        CAREER EXP {{ archetypeData.revizedCareerExp ? ' (OVERRIDE)' : '' }}
      </th>
    </tr>
    <tr>
      <td
        class="border-r-solid border-t-solid border-r border-t border-r-slate-200 border-t-slate-200 p-2 inter-medium text-gray-500 text-sm"
      >
        {{ archetypeData.name }}
      </td>
      <td
        class="border-r-solid border-t-solid border-r border-t border-r-slate-200 border-t-slate-200 p-2 inter-medium text-gray-500 text-sm"
      >
        {{ archetypeCurrentPositionTitle }}
      </td>
      <td
        class="border-r-solid border-t-solid border-r border-t border-r-slate-200 border-t-slate-200 p-2 inter-medium text-gray-500 text-sm"
      >
        {{ archetypeData.employer ?? 'N\\A' }}
      </td>
      <td
        class="border-r-solid border-t-solid border-r border-t border-r-slate-200 border-t-slate-200 p-2 inter-medium text-gray-500 text-sm"
      >
        {{ archetypeData.industry ?? 'N\\A' }}
      </td>
      <!-- <td
                            class="border-r-solid border-t-solid border-r border-t border-r-slate-200 border-t-slate-200 p-2"
                        >
                            {{archetypeData.education }}
                        </td> -->
      <td
        class="border-r-solid border-t-solid border-r border-t border-r-slate-200 border-t-slate-200 p-2 inter-medium text-gray-500 text-sm"
      >
        {{ archetypeData.location ?? 'N\\A' }}
      </td>
      <td
        class="border-r-solid border-t-solid border-r border-t border-r-slate-200 border-t-slate-200 p-2 inter-medium text-gray-500 text-sm"
      >
        {{ convertMonthsToYearsMonths(archetypeData.revizedTotalRoleExp ?? archetypeData.roleExp) }}
      </td>
      <td class="border-t border-t-slate-200 p-2 inter-medium text-gray-500 text-sm">
        {{ convertMonthsToYearsMonths(archetypeData.revizedCareerExp ?? archetypeData.careerExp) }}
      </td>
    </tr>
  </table>
</template>

<script lang="ts" setup>
import { computed, defineProps } from 'vue'

import { convertMonthsToYearsMonths } from '../../../utils/date'

interface IProps {
  archetypeData: Record<string, any>
}

const props = defineProps<IProps>()

const archetypeCurrentPositionTitle = computed(() =>
  props.archetypeData.revisedPosition
    ? props.archetypeData.revisedPosition
    : props.archetypeData.position ?? 'N\\A'
)
</script>
