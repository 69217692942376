<template>
  <div class="flex flex-col">
    <div class="h-14 bg-white flex justify-between">
      <div class="flex">
        <RouterLink to="/" class="self-center pl-4 border-none">
          <img class="cursor-pointer" src="@/assets/Lookalikes.png" alt="logo" width="180" />
        </RouterLink>
        <RouterLink
          to="/"
          exact-active-class="custom-active-class"
          class="self-center ml-12 h-full flex"
        >
          <span class="self-center inter-medium">Lookalikes Search</span>
        </RouterLink>
        <RouterLink
          to="/shortlist"
          exact-active-class="custom-active-class"
          class="self-center ml-8 h-full flex"
        >
          <span class="self-center inter-medium">Sourcing Projects</span>
        </RouterLink>
      </div>
      <div class="flex">
        <div class="relative inline-block text-left">
          <div class="mt-3 mr-3 flex">
            <ToolTip text="Invite people" css-classes="ml-[-90px] w-[120px] mt-2">
              <template #default>
                <div class="cursor-pointer hover:bg-gray-200 rounded-full p-2 mt-[-5px]">
                  <InviteUserIcon class="text-sm self-end w-5 h" @click="sendInvites = true">
                  </InviteUserIcon>
                </div>
              </template>
            </ToolTip>

            <button
              type="button"
              class="inline-flex bg-white text-sm py-1 ml-2"
              @click="displayMenu = true"
              @focusout="handleFocusOut"
            >
              {{ userStore.getUserName ?? '' }}
              <DownIcon></DownIcon>
            </button>
          </div>
          <div v-if="displayMenu" class="absolute right-1 w-56 rounded-md bg-white z-10 shadow-md">
            <div class="p-2">
              <RouterLink to="/profile" role="menu">
                <span
                  class="hover:bg-gray-200 rounded-md h-9 p-2 flex cursor-pointer px-4 text-sm text-gray-700"
                  @click="displayMenu = false"
                >
                  <SecurityIcon class="mr-2"></SecurityIcon>
                  Account & Security
                </span>
              </RouterLink>

              <logout-button
                class="w-[100%] block px-4 py-2 text-sm text-gray-700"
                role="menu"
              ></logout-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="px-6 py-3 min-h-[94vh] bg-slate-100">
      <router-view></router-view>
    </div>

    <div v-if="sendInvites">
      <div class="absolute w-screen h-screen bg-gray-400 opacity-50 top-0"></div>
      <BaseCardWithHeader
        class="absolute right-[37%] top-12 w-[430px] pb-2"
        custom-css-for-header="!p-2"
        :no-flex="true"
        title="Invite People"
      >
        <template #headerSection>
          <CloseButton
            class="ml-auto top-[14px] absolute right-4 h-4 w-4"
            @click="closeSendInviteModal"
          ></CloseButton>
        </template>
        <template #main>
          <p class="text-gray-500 m-3 mb-0">
            Invite your colleagues and friends to use Lookalikes.
          </p>

          <div class="w-full mt-2">
            <p class="text-rose-600 mb-2 ml-3 pr-2" v-if="inviteError && !inviteSuccess">
              {{ inviteErrorMessage }}
            </p>
            <p class="text-green-600 mb-2 ml-3" v-if="inviteSuccess && !inviteError">
              {{ inviteSuccessMsg }}
            </p>

            <form @submit.prevent="sendInvite" class="flex flex-col">
              <Input
                placeholder="Email"
                name="email"
                type="email"
                class="m-3"
                label-size="small"
                required
                :value="email"
                @input-change="(value) => (email = value)"
              />

              <BaseButton
                class="mt-2 mb-2 !mr-3 self-end"
                text="Send Invite"
                type="submit"
                text-align-center
                :loading="loading"
              />
            </form>
          </div>
        </template>
      </BaseCardWithHeader>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import { RouterView } from 'vue-router'
import LogoutButton from '@/components/LogoutButton.vue'
import DownIcon from '@/components/icons/DownIcon.vue'
import SecurityIcon from '@/components/icons/SecurityIcon.vue'

import { useUserStore } from '@/stores/user'
import InviteUserIcon from '@/components/icons/InviteUserIcon.vue'
import BaseCardWithHeader from '@/components/Cards/BaseCards/BaseCardWithHeader.vue'
import Input from '@/components/inputs/Input.vue'
import BaseButton from '@/components/BaseButton.vue'
import axios from 'axios'
import { captureException } from '@sentry/vue'
import CloseButton from '@/components/CloseButton.vue'
import ToolTip from '@/components/ToolTip.vue'

const userStore = useUserStore()

const displayMenu = ref(false)
const sendInvites = ref(false)
const email = ref('')
const loading = ref(false)
const inviteSuccess = ref(false)
const inviteSuccessMsg = ref('')
const inviteError = ref(false)
const inviteErrorMessage = ref('')

const handleFocusOut = (event) => {
  if (!event.relatedTarget || event.relatedTarget.role !== 'menu') {
    displayMenu.value = false
  }
}

const sendInvite = async () => {
  loading.value = true
  inviteError.value = false
  inviteSuccess.value = false

  try {
    const res = await axios.post(`${import.meta.env.VITE_LOOKALIKES_API}/api/user/invite`, {
      email: email.value
    })

    inviteSuccessMsg.value = `Invite was sent successfully to ${email.value}.`
    inviteSuccess.value = true
    email.value = ''
  } catch (err) {
    inviteError.value = true
    inviteErrorMessage.value =
      err.response.status === 422
        ? err.response.data.message
        : 'Oops, something went wrong. please try again.'

    captureException(err)
  } finally {
    loading.value = false
  }
}

const closeSendInviteModal = () => {
  sendInvites.value = false
  email.value = null
  inviteSuccess.value = false
  inviteError.value = false
}
</script>

<style scoped>
.custom-active-class {
  @apply border-b-2 border-primary-500;
}
</style>
