<template>
  <div class="flex flex-col">
    <div class="flex">
      <AutoCompleteInput
        class="flex-1"
        title="country"
        :static-options="
          countries.filter((country) => !recentlySearchedCountries.includes(country))
        "
        :show-static-options-on-focus="true"
        :show-recently-searched="true"
        :recently-searched-values="recentlySearchedCountries"
        :multi-select="true"
        :multi-selected-values="convertArrToObj(localSelectedCountries)"
        :hide-options="hideCountryOptions"
        @option-selected="onCountryChange"
        @focus="handleFocusOnCountry"
        @focusout="handleFocusOutCountry"
      />
      <AutoCompleteInput
        class="flex-1 mr-4 ml-4"
        title="State"
        :static-options="states"
        :show-static-options-on-focus="true"
        :show-recently-searched="true"
        :recently-searched-values="recentlySearchedStates"
        :multi-select="true"
        :multi-selected-values="convertArrToObj(localSelectedStates)"
        :hide-options="hideStateOptions"
        @option-selected="onStateChange"
        @focus="handleFocusOnState"
        @focusout="handleFocusOutState"
      />
      <AutoCompleteInput
        class="flex-1"
        title="City"
        :static-options="cities"
        :show-static-options-on-focus="true"
        :show-recently-searched="true"
        :recently-searched-values="recentlySearchedCities"
        :multi-select="true"
        :multi-selected-values="convertArrToObj(localSelectedCity)"
        :hide-options="hideCityOptions"
        @option-selected="onCityChange"
        @focus="handleFocusOnCity"
        @focusout="handleFocusOutCity"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'

import axios from 'axios'
import AutoCompleteInput from '@/components/inputs/AutoCompleteInput.vue'
import { captureException } from '@sentry/vue'
import { convertArrToObj } from '@/utils/type-format-helper'
import { getLocationRecentSearches } from '@/utils/recent-searches'
import { useLookalikesStore } from '@/stores/lookalikes'

interface IProps {
  showRecentlySearched?: boolean
  selectedCountries?: string[]
  selectedStates?: string[]
  selectedCities?: string[]
  statesOptions?: string[]
  citiesOptions?: string[]
}

const props = defineProps<IProps>()

const countries = [
  'Andorra',
  'Australia',
  'Austria',
  'Bangladesh',
  'Belgium',
  'Brazil',
  'Bulgaria',
  'Canada',
  'Chile',
  'Colombia',
  'Croatia',
  'Cyprus',
  'Denmark',
  'Estonia',
  'Finland',
  'France',
  'Georgia',
  'Germany',
  'Hungary',
  'India',
  'Ireland',
  'Israel',
  'Italy',
  'Japan',
  'South Korea',
  'Lithuania',
  'Mexico',
  'Netherlands',
  'New Zealand',
  'Norway',
  'Philippines',
  'Poland',
  'Portugal',
  'Romania',
  'Russia',
  'Serbia',
  'Singapore',
  'South Africa',
  'Spain',
  'Sweden',
  'Switzerland',
  'Taiwan',
  'Thailand',
  'Turkey',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States'
]

const lookalikesStore = useLookalikesStore()
const localSelectedCountries = ref<string[]>(lookalikesStore.countries ?? [])
const localSelectedStates = ref<string[]>(lookalikesStore.states ?? [])
const localSelectedCity = ref(lookalikesStore.cities ?? [])
const states = ref(lookalikesStore.statesOptions ?? [])
const cities = ref(lookalikesStore.citiesOptions ?? [])
const hideCountryOptions = ref(false)
const hideStateOptions = ref(false)
const hideCityOptions = ref(false)

const recentlySearchedCountries = ref(getLocationRecentSearches('countries', 3))
const recentlySearchedStates = ref(getLocationRecentSearches('states', 3, states.value))
const recentlySearchedCities = ref(getLocationRecentSearches('cities', 3, cities.value))

const onCountryChange = async (val: string[]): Promise<void> => {
  if (val.length === 0) {
    states.value = []
    cities.value = []

    localSelectedStates.value = []
    localSelectedCity.value = []

    lookalikesStore.setCountries([])
    return
  }

  localSelectedCountries.value = val

  try {
    const res = await axios(`${import.meta.env.VITE_LOOKALIKES_API}/api/lookalikes/search/states`, {
      params: {
        countries: JSON.stringify(localSelectedCountries.value)
      }
    })

    recentlySearchedStates.value = getLocationRecentSearches('states', 3, res.data)
    states.value = res.data.filter((item) => {
      return !recentlySearchedStates.value.includes(item)
    })

    lookalikesStore.setCountries(val)
    lookalikesStore.setStatesOptions(states.value)
  } catch (err) {
    captureException(err)
  }
}

const onStateChange = async (val: string[]): Promise<void> => {
  if (val.length === 0) {
    localSelectedCity.value = []
    lookalikesStore.setStates([])
    return
  } else {
    localSelectedStates.value = val

    const res = await axios.get(
      `${import.meta.env.VITE_LOOKALIKES_API}/api/lookalikes/search/cities`,
      {
        params: {
          countries: JSON.stringify(localSelectedCountries.value),
          states: JSON.stringify(localSelectedStates.value)
        }
      }
    )

    localSelectedCity.value = []
    recentlySearchedCities.value = getLocationRecentSearches('cities', 3, res.data)
    cities.value = res.data.filter((item) => {
      return !recentlySearchedCities.value.includes(item)
    })

    lookalikesStore.setStates(val)
    lookalikesStore.setCitiesOptions(cities.value)
  }
}

const onCityChange = (val: string[]): void => {
  if (val.length === 0) {
    localSelectedCity.value = []
    lookalikesStore.setCities([])
    return
  }
  localSelectedCity.value = val
  lookalikesStore.setCities(val)
}

const handleFocusOutCountry = (event) => {
  if (
    event.explicitOriginalTarget &&
    event.explicitOriginalTarget.dataset &&
    event.explicitOriginalTarget.dataset.id !== 'multi-select-option'
  ) {
    hideCountryOptions.value = true
  }
}

const handleFocusOnCountry = () => {
  hideCountryOptions.value = false
  hideStateOptions.value = true
  hideCityOptions.value = true
}

const handleFocusOutState = (event) => {
  if (
    event.explicitOriginalTarget &&
    event.explicitOriginalTarget.dataset &&
    event.explicitOriginalTarget.dataset.id !== 'multi-select-option'
  ) {
    hideStateOptions.value = true
  }
}

const handleFocusOnState = () => {
  hideCountryOptions.value = true
  hideStateOptions.value = false
  hideCityOptions.value = true
}

const handleFocusOutCity = (event) => {
  if (
    event.explicitOriginalTarget &&
    event.explicitOriginalTarget.dataset &&
    event.explicitOriginalTarget.dataset.id !== 'multi-select-option'
  ) {
    hideCityOptions.value = true
  }
}

const handleFocusOnCity = () => {
  hideCountryOptions.value = true
  hideStateOptions.value = true
  hideCityOptions.value = false
}

onMounted(async () => {
  window.addEventListener('changed-recently-searches', () => {
    recentlySearchedCountries.value = getLocationRecentSearches('countries', 3)
    recentlySearchedStates.value = getLocationRecentSearches('states', 3, states.value)
    recentlySearchedCities.value = getLocationRecentSearches('cities', 3, cities.value)
  })

  if (localSelectedCountries.value.length > 0) {
    try {
      const res = await axios(
        `${import.meta.env.VITE_LOOKALIKES_API}/api/lookalikes/search/states`,
        {
          params: {
            countries: JSON.stringify(localSelectedCountries.value)
          }
        }
      )

      states.value = res.data
      lookalikesStore.setStatesOptions(states.value)
    } catch (err) {
      captureException(err)
    }
  }

  if (localSelectedStates.value.length > 0) {
    try {
      const res = await axios.get(
        `${import.meta.env.VITE_LOOKALIKES_API}/api/lookalikes/search/cities`,
        {
          params: {
            countries: JSON.stringify(localSelectedCountries.value),
            states: JSON.stringify(localSelectedStates.value)
          }
        }
      )

      cities.value = res.data
      lookalikesStore.setCitiesOptions(cities.value)
    } catch (err) {
      captureException(err)
    }
  }
})
</script>
