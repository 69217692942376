<template>
  <p v-if="route.query.email" class="text-center mb-4 font-bold">
    You've Successfully registered for your free trial. Login Below.
  </p>
  <BaseCard class="max-w-[448px] card-shadow m-auto">
    <template #main>
      <div class="m-auto p-8 w-full">
        <p v-if="errorMessage" class="text-rose-600">{{ errorMessage }}</p>
        <form @submit.prevent="login">
          <Input
            title="email"
            name="email"
            type="email"
            :value="email"
            required
            @input-change="(value) => (email = value)"
          />

          <Input
            class="mt-8"
            title="password"
            name="password"
            type="password"
            required
            @input-change="(value) => (password = value)"
          />

          <div class="flex">
            <div class="mr-auto mt-8 flex justify-start gap-4">
              <RouterLink class="text-sm self-center text-gray-500 underline" to="/register">
                Sign Up
              </RouterLink>
            </div>
            <div class="ml-auto mt-8 flex justify-end gap-4">
              <RouterLink class="text-sm self-center underline text-gray-500" to="/forgot-password"
                >Forgot your password?</RouterLink
              >
              <BaseButton text="Login" type="submit" :loading="loading" />
            </div>
          </div>
        </form>
      </div>
    </template>
  </BaseCard>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { update } from '@intercom/messenger-js-sdk'
import axios from 'axios'

import Input from '@/components/inputs/Input.vue'
import BaseButton from '@/components/BaseButton.vue'
import BaseCard from '@/components/BaseCard.vue'
import { useUserStore } from '@/stores/user'

import { captureException } from '@sentry/vue'

onMounted(() => {
  update({ hide_default_launcher: true })
})

const router = useRouter()
const route = useRoute()
const userStore = useUserStore()

const email = ref((route.query.email as string) ?? '')
const password = ref('')
const errorMessage = ref('')
const loading = ref(false)

const login = async () => {
  loading.value = true

  try {
    await axios.get(`${import.meta.env.VITE_LOOKALIKES_API}/sanctum/csrf-cookie`)

    const res = await axios.post(`${import.meta.env.VITE_LOOKALIKES_API}/login`, {
      email: email.value,
      password: password.value
    })

    const user = res.data
    userStore.login(user)

    const redirect = router.currentRoute.value.query.redirect || '/'

    router.push(redirect)
  } catch (error) {
    if (error.response && error.response.status === 401) {
      errorMessage.value = error.response.data
    } else {
      captureException(error)
      errorMessage.value = 'Oops,something went wrong. please contact support'
    }
  } finally {
    loading.value = false
  }
}
</script>

<style scoped>
.card-shadow {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
</style>
