<template>
  <div class="flex flex-col">
    <label
      :class="`mb-1 ${labelSize}  text-gray-500 inter-bold uppercase tracking-wide `"
      :for="labelId"
      >{{ title }}</label
    >
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import { computed, defineProps, withDefaults } from 'vue'

import type { LabelSize } from './interfaces'

export interface IProps {
  title?: string
  labelId?: string
  labelSize?: LabelSize
}

const props = withDefaults(defineProps<IProps>(), {
  labelSize: 'small'
})

const labelSize = computed(
  () =>
    ({
      small: 'text-xs',
      medium: 'text-base',
      large: 'text-lg'
    })[props.labelSize]
)
</script>
