<template>
  <button
    class="focus:outline-acne mr-3 flex items-center rounded px-3 text-sm font-bold text-white shadow ring-primary-200 last:mr-0 first:last:mr-3 focus:ring dark:text-gray-800 dark:ring-gray-600"
    :class="[
      hoverColors[bgColor] ?? hoverColors.default,
      bgColors[bgColor] ?? bgColors.default,
      height ? height : 'h-9'
    ]"
    :disabled="disabled"
    :type="type"
    :form="formId"
    @click.stop="$emit('onClick')"
  >
    <div v-if="!loading" :class="{ 'm-auto': textAlignCenter, 'text-lg': largeText }" class="flex">
      {{ text }}
      <slot></slot>
    </div>

    <Loader v-if="loading" class="w-10" :class="{ 'm-auto': alignLoaderCenter }" />
  </button>
</template>

<script setup lang="ts">
import { defineEmits, defineProps } from 'vue'

import Loader from './Loader.vue'

type BaseButtonColors = 'default' | 'green' | 'red' | 'yellow' | 'darkBlue'

interface IProps {
  text?: string
  bgColor?: BaseButtonColors
  disabled?: boolean
  type?: string
  loading?: boolean
  formId?: string
  height?: string
  textAlignCenter?: boolean
  alignLoaderCenter?: boolean
  largeText?: boolean
}

defineEmits(['onClick'])
defineProps<IProps>()

const bgColors = {
  default: 'bg-primary-500',
  green: 'bg-green-500',
  red: 'bg-red-500',
  yellow: 'bg-yellow-500',
  darkBlue: 'bg-blue-900',
  blue: 'bg-[#225DE6]'
}

const hoverColors = {
  default: 'hover:bg-primary-400',
  green: 'hover:bg-green-400',
  red: 'hover:bg-red-400',
  yellow: 'hover:bg-yellow-400',
  darkBlue: 'hover:bg-blue-800',
  blue: 'hover:bg-[#1b4ab6]'
}
</script>


<style scoped>
  @media (prefers-color-scheme: dark) {
    button {
      color: white !important;
    }
  }
</style>
