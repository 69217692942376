export const setRecentViewed = (candidateProfileId: string) => {
  cleanup()

  const views = localStorage.getItem('recent-viewed') ? JSON.parse(localStorage.getItem('recent-viewed')) : {}

  if (views[candidateProfileId] == undefined) {
    views[candidateProfileId] = {
      date: Date.now(),
      count: 1,
    }
  } else {
    views[candidateProfileId].count = views[candidateProfileId].count + 1
    views[candidateProfileId].date = Date.now()
  }

  localStorage.setItem('recent-viewed', JSON.stringify(views))
}

export const isRecentViewed = (candidateProfileId: string, displayAtFirstTime: boolean) => {
  if (!localStorage.hasOwnProperty('recent-viewed')) return false

  const views = JSON.parse(localStorage.getItem('recent-viewed'))

  return displayAtFirstTime ? views[candidateProfileId] !== undefined : (views[candidateProfileId] !== undefined && views[candidateProfileId].count > 1)
}

const cleanup = () => {
  if (localStorage.hasOwnProperty('recent-viewed')) {
    const views = JSON.parse(localStorage.getItem('recent-viewed'))

    // Calculate the Unix timestamp for 14 days ago in milliseconds
    const fourteenDaysAgo = Date.now() - (14 * 24 * 60 * 60 * 1000);

    for (const key in views) {
      if (views[key].date < fourteenDaysAgo) {
        delete views[key];
      }
    }

    localStorage.setItem('recent-viewed', JSON.stringify(views))
  }
}
