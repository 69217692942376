<template>
  <BaseButton
    :text="text"
    :loading="loading"
    :disabled="isShortlisted !== undefined ? isShortlisted : isShortlistedInState"
    :bg-color="
      (isShortlisted !== undefined ? isShortlisted : isShortlistedInState) ? 'darkBlue' : 'default'
    "
    @click="shortlist"
  />
</template>

<script lang="ts" setup>
import { computed, defineEmits, defineProps, ref } from 'vue'
import BaseButton from '@/components/BaseButton.vue'

import { useLookalikesStore } from '@/stores/lookalikes'

import { captureException } from '@sentry/vue'
import axios from 'axios'

interface IProps {
  searchId: number
  archetypeLiProfileId: string
  candidateLiProfileId: string
  isShortlisted?: boolean
  jobTitle: string
}

const emit = defineEmits(['shortlistFailed', 'shortlistSucceed'])
const props = defineProps<IProps>()
const store = useLookalikesStore()

const loading = ref(false)
const localShortlist = ref(false)

const isShortlistedInState = computed(() => store.shortlist.includes(props.candidateLiProfileId))

const text = computed(() =>
  (props.isShortlisted !== undefined ? props.isShortlisted : isShortlistedInState.value)
    ? 'Shortlisted'
    : 'Shortlist'
)

const shortlist = async () => {
  loading.value = true

  try {
    await axios.post(`${import.meta.env.VITE_LOOKALIKES_API}/api/shortlist`, {
      linkedin_profile_id: props.candidateLiProfileId,
      job_title: props.jobTitle,
      search_id: props.searchId
    })

    localShortlist.value = true
    store.addCandidateLiIdToShortlist(props.candidateLiProfileId)
    emit('shortlistSucceed')
  } catch (error) {
    console.log(error.response.status, error.response?.data.message)
    captureException(error)
    if (error.response.status === 400) {
      emit('shortlistFailed', error.response?.data.message)
    } else {
      emit('shortlistFailed', 'Oops something went wrong. please contact our support team')
    }
  } finally {
    loading.value = false
  }
}
</script>
