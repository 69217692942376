<template>
  <BaseModal class="!p-0" @close="closeModal">
    <template #default>
      <div class="flex justify-center w-[1000px] h-[700px]">
        <EditArchetypeProfile
          text-for-search-button="Edit Search"
          :edited-search="true"
          @lookalikes-search-finished="
            $emit('close'), $emit('searchEdited'), (loadingLookAlikeSearch = false)
          "
          @searching-for-lookalikes="
            (loadingLookAlikeSearch = true), $emit('searchingForLookalikes')
          "
          @error="$emit('error'), $emit('close')"
        />
      </div>
    </template>
  </BaseModal>
</template>

<script lang="ts" setup>
import { defineEmits, onMounted, ref } from 'vue'

import BaseModal from '@/components/BaseModal.vue'
import EditArchetypeProfile from '@/views/Home/Tabs/LookalikeSearch/components/EditArchetypeProfile.vue'
import { useLookalikesStore } from '@/stores/lookalikes'

import axios from 'axios'

const emit = defineEmits(['close', 'searchEdited', 'searchingForLookalikes', 'error'])
const lookalikesStore = useLookalikesStore()

const loadingLookAlikeSearch = ref(false)

onMounted(async () => {
  if (lookalikesStore.archetypeProfile !== null) return

  try {
    const res = await axios.get(
      `${import.meta.env.VITE_LOOKALIKES_API}/api/lookalikes/getArchetypeProfileForSearchEdit/${lookalikesStore.lookalikeCurrentHistorySearchId}`
    )

    lookalikesStore.setArchetypeProfile(structuredClone(res.data.profile_data_for_search))
    lookalikesStore.setArchetypeLiProfileUrl(`https://www.linkedin.com/in/${res.data.profile_id}`)
    lookalikesStore.setRemovedExpItems(res.data.deleted_exp_items)

    lookalikesStore.setCountries(res.data.countries)
    lookalikesStore.setStates(res.data.states)
    lookalikesStore.setCities(res.data.cities)
  } catch (error) {}
})

const closeModal = () => {
  if (loadingLookAlikeSearch.value) return

  emit('close')
}
</script>
