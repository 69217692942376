<template>
  <div class="relative h-96 flex-1">
    <VueApexCharts :options="chartOptions" :series="series" height="100%" />
    <div
      v-if="mockData"
      class="absolute top-0 z-[99] flex h-full w-full items-center justify-center bg-white/50"
    >
      <p class="text-center text-xl text-black">
        {{ noDataMessage ?? 'Oops! It appears we are still crunching the data for this chart.' }}
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { defineProps, ref } from 'vue'
import VueApexCharts from 'vue3-apexcharts'
import type { VueApexChartsComponent } from 'vue3-apexcharts'
import type { ApexOptions } from 'apexcharts'

interface IProps {
  series: VueApexChartsComponent['series']
  categories: number[] | string
  mockData?: boolean,
  noDataMessage?: string,
  dataValueFormatter(val: number[] | number | string, opts?: never): string
}

const props = defineProps<IProps>()

const chartOptions = ref<ApexOptions>({
  chart: {
    type: 'area'
  },
  stroke: {
    curve: 'smooth'
  },
  fill: {
    type: 'gradient'
  },
  xaxis: {
    categories: props.categories
  },
  yaxis: {
    labels: {
      formatter: props.dataValueFormatter
    },
    min: 0,
    forceNiceScale: true
  },
  dataLabels: {
    enabled: true,

    formatter: props.dataValueFormatter
  }
})
</script>
