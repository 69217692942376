<template>
  <AnalyzingArchetype v-if="loading" />
  <div v-else class="p-4">
    <Input
      class="mb-4"
      title="ARCHETYPE LINKEDIN URL"
      placeholder="Linkedin URL"
      :value="linkedinUrl"
      :is-valid="isLiUrlValid"
      invalid-message="The provided Linkedin url is not valid"
      data-hj-allow
      @input-change="(value) => (linkedinUrl = value)"
    />
    <SearchLocationFilter :show-recently-searched="true" />

    <div class="mb-[20px]">
      <BaseButton
        class="mt-4"
        :loading="loading"
        type="button"
        text="Let's Go"
        @on-click="getArchetypeProfileData"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, defineEmits, computed } from 'vue'
import Input from '@/components/inputs/Input.vue'
import BaseButton from '@/components/BaseButton.vue'
import SearchLocationFilter from '@/components/SearchLocationFilter.vue'
import AnalyzingArchetype from '../components/AnalyzingArchetype.vue'

import { setLocationSearches } from '@/utils/recent-searches'
import { useLookalikesStore } from '@/stores/lookalikes'

import axios from 'axios'

const emit = defineEmits(['error', 'analyzedArchetype'])

const lookalikesStore = useLookalikesStore()

const countries = computed(() => lookalikesStore.countries)
const states = computed(() => lookalikesStore.states)
const cities = computed(() => lookalikesStore.cities)
const linkedinUrl = ref('')
const isLiUrlValid = ref(true)
const loading = ref(false)

let urlParams = new URLSearchParams(window.location.search)

if (urlParams.get('liId')) {
  linkedinUrl.value = `https://www.linkedin.com/in/${urlParams.get('liId')}`
}

const getArchetypeProfileData = async (): Promise<void> => {
  const regex = /http(s)?:\/\/([\w]+\.)?linkedin\.com\/in\/[A-z0-9_-]+\/?/

  isLiUrlValid.value = regex.test(linkedinUrl.value)

  if (!isLiUrlValid.value) return
  loading.value = true

  setLocationSearches(countries.value, states.value, cities.value)

  try {
    const res = await axios.get(
      `${import.meta.env.VITE_LOOKALIKES_API}/api/lookalikes/getArchetypeProfile?linkedinProfileUrl=${linkedinUrl.value}`
    )

    lookalikesStore.setArchetypeProfile(res.data)
    lookalikesStore.setArchetypeLiProfileUrl(linkedinUrl.value)

    emit('analyzedArchetype')
  } catch (err) {
    emit('error')
  } finally {
    loading.value = false
  }
}
</script>
