<template>
  <div class="mb-6">
    <h1 class="mt-0 mb-4 text-3xl blinker-semibold leading-9 text-slate-800">
      New Lookalikes Search
    </h1>
    <p class="leading-6 text-gray-500 inter-medium">
      Select a method and start a new search by providing an Archetype profile URL or by creating an
      Archetype precisely to your needs.
    </p>
    <p class="leading-6 text-gray-500 inter-medium">
      Then, hit "Let's Go" and let our AI deliver top candidates matching your exact requirements in
      seconds.
    </p>
  </div>

  <TabbedPage :tabs="tabs" />
</template>

<script setup lang="ts">
import { markRaw } from 'vue'

import TabbedPage from '@/components/tabbedPage/TabbedPage.vue'
import LookalikeSearch from './Tabs/LookalikeSearch/LookalikeSearch.vue'
import BuildArchetypeSearchTab from './Tabs/BuildArchetypeSearchTab.vue'

import type { Tabs } from '@/components/tabbedPage/interfaces.ts'

const tabs: Tabs = {
  'lookalike-search': { displayName: 'Classic Lookalikes', component: markRaw(LookalikeSearch) },
  'build-archetype': {
    displayName: 'Archetype Builder',
    component: markRaw(BuildArchetypeSearchTab)
  }
}
</script>
