import { ref, unref, watchEffect } from 'vue'
import type { Ref } from 'vue'
import { captureException } from '@sentry/vue'

import axios from 'axios'

export const useFetch = <T>(
  url: Ref<string> | string,
  config?: Record<string, any>
): {
  data: Ref<T | null | undefined>
  loading: Ref<boolean>
  error: Ref<boolean>
  errorMessage: Ref<string | undefined>
  isAuthorized: Ref<boolean>
} => {
  const data = ref<T | null>()
  const error = ref(false)
  const errorMessage = ref<string>()
  const loading = ref(false)
  const isAuthorized = ref(true)

  const fetchData = (): void => {
    data.value = null
    error.value = false
    loading.value = true
    isAuthorized.value = true

    axios<T>(unref(url), config)
      .then((res) => {
        if (res || (res.status >= 200 && res.status < 300)) {
          data.value = <T>res.data

          return
        }

        if (res.status === 401) {
          isAuthorized.value = false
        }

        throw new Error(`Request failed with status: ${res.status} ${res.statusText}.`)
      })
      .catch((err) => {
        error.value = true
        errorMessage.value = err.toString()
        console.error(err)
        captureException(err)
      })
      .finally(() => {
        loading.value = false
      })
  }

  watchEffect(() => {
    fetchData()
  })

  return { data, loading, error, errorMessage, isAuthorized }
}
