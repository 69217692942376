<template>
  <BaseTab>
    <component :is="currentStep" @analyzed-archetype="changeStep" @error="error = true" />
  </BaseTab>

  <Toaster
    v-if="error"
    class="fixed"
    message="Oops something went wrong. please contact our support team"
    color="red"
    :time="5000"
    @hide-toaster="error = false"
  />
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import type { Component } from 'vue'

import BaseTab from '@/components/BaseTab.vue'
import StartSearch from './components/StartSearch.vue'
import EditArchetypeProfile from './components/EditArchetypeProfile.vue'
import Toaster from '@/components/Toaster.vue'

const currentStep = ref<Component>(StartSearch)
const error = ref(false)

const changeStep = () => {
  currentStep.value = EditArchetypeProfile
}
</script>
