<template>
  <CompareToAverageCardWithHeader
    title="Position Demand Score"
    description="Position Demand is scored between 1% and 99%, with 99% indicating there is nearly as much hiring demand as there are people currently in this position, in your employee’s geographic region. This score is calculated as a rolling analysis of regional position-specific hiring posts, compared with the number of people currently employed in this position in the regional workforce."
    :data="typeof data === 'number' ? data + '%' : null"
    not-available-data-message="Position Demand Not Available"
    :percentage-value="percentageValue"
    :percentage-value-title="percentageValueTitle"
    :up-down-same="upDownSame"
    data-title="Position Demand Score"
  ></CompareToAverageCardWithHeader>
</template>

<script lang="ts" setup>
import { computed, defineProps } from 'vue'

import CompareToAverageCardWithHeader from '@/components/Cards/MetricCards/CompareToAverageCardWithHeader/CompareToAverageCardWithHeader.vue'
import type { UpDownSame } from '@/components/interfaces'

interface IProps {
  percentageValue?: number
  upDownSame?: UpDownSame
  data: number | null
}

const props = defineProps<IProps>()

const percentageValueTitle = computed(() => {
  switch (props.upDownSame) {
    case 'up':
      return 'higher compared to 6 months ago'
    case 'down':
      return 'lower compared to 6 months ago'
    case 'same':
      return 'same as compared to 6 months ago'
    default:
      return 'Data for this position/demand did not load.'
  }
})
</script>
