<template>
  <BaseTab class="rounded-b-lg bg-white px-5 py-2 drop-shadow-md">
    <ToggleButton
      @toggle="showDataExplanation = !showDataExplanation"
      :toggle-state="false"
      label="Show Data Explanations"
    />
    <MarketCompensationPanel
      :loading="loading"
      :location="marketData.employeeLocation ?? ''"
      :average-annual-salary-percentage-value="marketData.annualSalaryPercentageChange"
      :average-annual-salary="marketData.averageAnnualSalary"
      :up-down-same="marketData.salaryChangeLabel"
      average-annual-salary-currency-title="USD/yr"
      :salary-history-data="marketData.salaryHistory"
      months-ago="6"
      :show-data-explanation="showDataExplanation"
    />
    <PositionDemandPanel
      :loading="loading"
      :position-demand-score="marketData.positionDemand"
      :up-down-same="marketData.positionDemandSixMonthChangeLabel"
      :percentage-value="marketData.positionDemandSixMonthChange"
      :position-demand-historical-data="marketData.jobDemandHistory"
      :show-data-explanation="showDataExplanation"
    />
  </BaseTab>
</template>

<script lang="ts" setup>
import { computed, onBeforeMount, ref } from 'vue'
import { useRoute } from 'vue-router'

import BaseTab from '@/components/BaseTab.vue'
import MarketCompensationPanel from './MarketCompensationPanel.vue'
import PositionDemandPanel from './PositionDemandPanel.vue'
import ToggleButton from '@/components/ToggleButton.vue'
import axios from 'axios'
import { captureException } from '@sentry/vue'

interface IMarketData {
  employeeLocation: string
  annualSalaryPercentageChange: number
  averageAnnualSalary: string
  salaryChangeLabel: string
  salaryHistory: { salary: number; timestamp: Date }[]
  position_demand: number
  position_demand_change_label: string
  position_demand_percentage_change: number
  position_job_demand_history: { salary: number; timestamp: Date }[]
}

const route = useRoute()

const data = ref(null)
const loading = ref(false)
const error = ref(false)
const showDataExplanation = ref(false)

const marketData = computed(() => (!loading.value && !error.value && data.value ? data.value : []))

onBeforeMount(async () => {
  loading.value = true

  try {
    const res = await axios.get<IMarketData>(
      `${import.meta.env.VITE_LOOKALIKES_API}/api/look-alike/search/${route.params.searchId}/candidate/${route.params.candidateId}/market-data`
    )

    data.value = res.data
  } catch (err) {
    captureException(err)
    error.value = true
  } finally {
    loading.value = false
  }
})
</script>
