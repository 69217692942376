<template>
  <Breadcrumbs
    v-if="searchDate"
    :path="[
      {
        title: 'Lookalikes',
        url: '/'
      },
      {
        title: positionName + ' ' + searchDate,
        url: '/search-results/' + searchId
      }
    ]"
  />

  <Head title="Lookalikes" />
  <Loader v-if="loading" class="m-auto w-10 pt-10" />

  <div v-else class="flex">
    <h1 class="text-3xl blinker-semibold leading-9 text-slate-800">
      {{ profileName }} <span class="font-light capitalize">({{ positionName }})</span>
    </h1>
    <a :href="linkedinUrl" class="self-center" target="_blank" rel="noopener noreferrer">
      <LinkedinIcon class="ml-2 inline h-[20px] w-[20px]" />
    </a>
  </div>

  <TabbedPage
    v-if="!loading"
    class="mt-10"
    :tabs="tabs"
    :data="{
      searchId: searchId,
      shortlisted: shortlisted.value,
      candidateProfileId: candidateLinkedinProfileId,
      linkedinProfileName: profileName,
      jobTitle: positionName
    }"
  />
</template>

<script setup lang="ts">
import { computed, ref, markRaw, onBeforeMount } from 'vue'
import { useRoute } from 'vue-router'
import { useLookalikesStore } from '@/stores/lookalikes'

import TabbedPage from '@/components/tabbedPage/TabbedPage.vue'
import Overview from './Tabs/Overview/Overview.vue'
import Benchmarks from './Tabs/Benchmarks.vue'
import MarketData from './Tabs/MarketData/MarketData.vue'
import LinkedinIcon from '@/components/icons/LinkedinIcon.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'

import type { Tabs } from '@/components/tabbedPage/interfaces'
import axios from 'axios'
import { captureException } from '@sentry/vue'
import Loader from '@/components/Loader.vue'

const tabs: Tabs = {
  overview: { displayName: 'Overview', component: markRaw(Overview) },
  benchmarks: { displayName: 'Benchmarks', component: markRaw(Benchmarks) },
  'market-data': { displayName: 'Market Data', component: markRaw(MarketData) }
}

const route = useRoute()
const store = useLookalikesStore()

const searchId = route.params.searchId
const candidateLinkedinProfileId = ref('')

const data = ref(null)
const loading = ref(false)
const shortlisted = ref(false)

const profileName = computed(() => (!loading.value && data.value ? data.value.name : ''))
const positionName = computed(() => (!loading.value && data.value ? data.value.positionTitle : ''))
const searchDate = computed(() => (!loading.value && data.value ? data.value.searchDate : ''))

const linkedinUrl = computed(
  () => `https://www.linkedin.com/in/${candidateLinkedinProfileId.value}`
)

onBeforeMount(async () => {
  loading.value = true
  console.log('ANTON')
  try {
    const res = await Promise.all([
      axios.get(
        `${import.meta.env.VITE_LOOKALIKES_API}/api/look-alike/search/${searchId}/candidate/${route.params.candidateId}`
      ),
      store.fetchSearchResults(parseInt(searchId))
    ])
    console.log(res, 'TEST')
    candidateLinkedinProfileId.value = res[0].data.candidateLiProfileId
    data.value = res[0].data
    shortlisted.value = store.shortlist.includes(candidateLinkedinProfileId.value)
  } catch (error) {
    captureException(error)
  } finally {
    loading.value = false
  }
})
</script>
