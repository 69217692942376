export const convertArrToObj = (arr): Record<string, string> => {
  const obj = {}

  if (!arr) {
    return {}
  }

  arr.forEach((element) => {
    obj[element] = element
  })

  return obj
}
