<template>
  <a :href="link" :title="companyName" target="_blank">
    <Loader v-if="loading" class="w-10" />

    <img
      v-if="src"
      :src="src"
      :alt="companyName"
      ref="imgElement"
      :class="`${logoWidth ?? 'w-11'} ${logoHeight ?? 'h-11'} shadow-lg rounded-full max-w-full h-auto align-middle border-none`"
      @load="loaded"
    />

    <div
      v-else-if="!src && !loading"
      :class="`shadow-lg self-center rounded-full border-none cursor-pointer relative flex justify-center items-center ${logoWidth ?? 'w-11'} ${logoHeight ?? 'h-11'}`"
    >
      <NoImageIcon />
    </div>
  </a>
</template>
<script setup lang="ts">
import { defineProps, ref } from 'vue'

import NoImageIcon from '@/components/icons/NoImageIcon.vue'
import Loader from '@/components/Loader.vue'

interface IProps {
  link: string
  src: string | null
  companyName: string
  logoWidth?: string
  logoHeight?: string
}

const props = defineProps<IProps>()
const imgElement = ref<HTMLImageElement>()
const loading = ref(!!props.src)

const loaded = () => {
  loading.value = false
}
</script>
